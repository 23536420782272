import React from "react";
import {
  Redirect, Route, Switch
} from "react-router-dom";
// import Dashboard from "./dashboard/Dashboard.js";
import AppWrapper from "./components/AppWrapper";
import NotFound from "./components/NotFoundPage/404-page";
import { userRoleConstants } from "./constants/appconstants";
import AdminHome from "./features/AdminHome";
import CEOHome from './features/CEOHome';
import Connector from "./features/connector";
// Pages
import Dashboard from "./features/dashboard";
import FinAdmin from './features/finManager/finManagerIndex';
import Leads from "./features/leads";
import AdminLeads from "./features/leads/adminIndex";
import LoanEnquiry from "./features/LoanEnquiry";
import PaymentApproval from './features/paymentApproval/index';
import RedemptionMangement from './features/RedemptionManagement';
import Reports from "./features/Reports";
import Settings from "./features/settings";
import TestPage from "./features/TestPage";
import useAppContext from "./utils/useAppContext";
import Notification from "./features/notifications";
import CompletedLeads from "./features/leads/CompletedLeads";
import ApproveConnectorIndex from "./features/leads/ApproveConnectorIndex";



export default function Routes({ match }) {
  let authRoutes = null;
  let redirectUrl = null;
  const { user } = useAppContext();
  // const user = 'FIN_MANAGER';
  const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER } = userRoleConstants;
  console.log(user === CONNECTOR);

  if (user === CONNECTOR) {
    authRoutes = (
      <>
        <Route path={`${match.url}/home`} component={Dashboard} />
        <Route path={`${match.url}/leads`} component={Leads} />
        <Route path={`${match.url}/test`} component={TestPage} />
        {/* <Route path={`${match.url}/settings`} component={Settings} /> */}
        {/* <Route path={`${match.url}/connector`} component={Connector} /> */}
        <Route path={`${match.url}/redemption-management`} component={RedemptionMangement} />
        <Route path={`${match.url}/loan-enquiry`} component={LoanEnquiry} />
      </>
    );
  } else if (user === FIN_ADMIN) {
    authRoutes = (
      <>
        <Route path={`${match.url}/admin-home`} component={AdminHome} />
        <Route path={`${match.url}/admin-leads`} component={AdminLeads} />
        <Route path={`${match.url}/admin-loan-enquiry`} component={LoanEnquiry} />
        <Route path={`${match.url}/test`} component={TestPage} />
        <Route
          path={`${match.url}/admin-add-DigiRep`}
          component={Connector}
        />
        <Route path={`${match.url}/admin-settings`} component={Settings} />
        <Route path={`${match.url}/notification`} component={Notification} />
        <Route path={`${match.url}/approve-DigiRep`} component={ApproveConnectorIndex} />
      </>
    );
  } else if (user === "ADMINCEO") {
    authRoutes = (
      <>
        <Route path={`${match.url}/admin-home`} component={AdminHome} />
      </>
    );
  } else if (user === 'CEO') {
    authRoutes = (
      <>
        <Route path={`${match.url}/ceo-home`} component={CEOHome} />
        <Route path={`${match.url}/ceo-reports`} component={Reports} />
        <Route path={`${match.url}/notification`} component={Notification} />
      </>
    )
  } else if (user === 'FIN_MANAGER') {
    authRoutes = (
      <>
        <Route path={`${match.url}/home`} component={FinAdmin} />
        <Route path={`${match.url}/leads`} component={AdminLeads} />
        <Route path={`${match.url}/completed-payments`} component={CompletedLeads} />
        <Route path={`${match.url}/payment-approval`} component={PaymentApproval} />
        <Route path={`${match.url}/admin-loan-enquiry`} component={LoanEnquiry} />
        <Route
          path={`${match.url}/admin-add-DigiRep`}
          component={Connector}
        />
        <Route path={`${match.url}/admin-settings`} component={Settings} />
        <Route path={`${match.url}/notification`} component={Notification} />
      </>

    )
  } 

  if (user === FIN_ADMIN) {
    redirectUrl = (
      <>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/admin-home`} />
      </>
    );
  } else if (user === CONNECTOR) {
    redirectUrl = (
      <>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
      </>
    );
  }

  return (
    <AppWrapper>
      <Switch>
        {/* {redirectUrl} */}
        {authRoutes}
        <Route path="*" component={NotFound} />
      </Switch>
    </AppWrapper>
  );
}
