import React, { useState } from "react";
import {
  timeIntervalConstants,
  productConstants,
} from "../../../constants/appconstants";
import AppContext from "./AppContext";
import defaultContext from "./defaultContext";

var retrievedObject = localStorage.getItem("userRole");

let userRoleTemp =
  retrievedObject && JSON.parse(retrievedObject) !== null
    ? JSON.parse(retrievedObject)
    : null;

var retrievedUserId = localStorage.getItem("userId");

let userIdTemp =
  retrievedUserId && JSON.parse(retrievedUserId) !== null
    ? JSON.parse(retrievedUserId)
    : null;



const AppContextProvider = ({ children }) => {
  const [open, setOpen] = useState(defaultContext.isDrawerOpen); // true
  const [freqValue, setFreqValue] = useState(timeIntervalConstants.MTD);
  const [productType, setProductType] = useState("");
  const [bankName, setBankName] = useState("");
  const [user, setUser] = useState(userRoleTemp);
  const [connectorId, setConnectorId] = useState(userIdTemp);

  const signin = (cb, loginData) => {
    const { userRole = null, userId = null } = loginData;
    setUser(userRole);
    setConnectorId(userId);
    localStorage.setItem("userRole", JSON.stringify(userRole));
    localStorage.setItem("userId", JSON.stringify(userId));
    setTimeout(cb, 100);
  };

  const signout = (cb) => {
    setUser(null);
    window.localStorage.clear();
    // window.location.reload();
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const changeFreqValue = (value) => {
    setFreqValue(value);
  };
  const changeProductType = (value) => {
    setProductType(value);
  };
  const changeBankName = (value) => {
    setBankName(value);
  };

  return (
    <AppContext.Provider
      value={{
        open,
        toggleDrawer,
        freqValue,
        changeFreqValue,
        productType,
        changeProductType,
        connectorId,
        signin,
        signout,
        user,
        bankName,
        changeBankName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
