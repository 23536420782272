import { AccountBalance, AttachMoney, People, PinDrop } from '@mui/icons-material';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { default as React, useEffect, useState } from "react";
import { ApiUrl } from '../../helpers/getUrl';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
function TransactionSection() {
  const [result, setResult] = useState([]);

  useEffect(() => {
    getCeoCount();
  }, []);

  const getCeoCount = () => {
    axios.get(`${ApiUrl()}reports/projection`)
      .then(res => {
        setResult(res.data);
        console.log(res.data);
      }).catch(error => {
        console.error('There was an error!', error);
      });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Paper className="reports-card-container"  style={{backgroundColor: '#ffcece'}}>
            <ViewCarouselIcon />
            <div>
              <h1 className="m-0">{result?.totalProducts || 0}</h1>
              <p className="m-0">Products</p>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <Paper className="reports-card-container"  style={{backgroundColor: '#a4ffb3'}}>
            <AccountBalance />
            <div>
              <h1 className="m-0">{result?.totalBanks || 0}</h1>
              <p className="m-0">Banks</p>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <Paper className="reports-card-container" style={{backgroundColor: '#9efbff'}}>
            <PinDrop />
            <div>
              <h1 className="m-0">{result?.totalStates || 0}</h1>
              <p className="m-0">States</p>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <Paper className="reports-card-container" style={{backgroundColor: '#f2aeff'}}>
            <People />
            <div>
              <h1 className="m-0">{result?.totalConnectors || 0}</h1>
              <p className="m-0">Connectors</p>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default TransactionSection;
