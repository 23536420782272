import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";

const active = {
  background: "white",
  borderRadius: "5px",
  borderRight: 6,
  borderColor: "#40c4ff",
  '&:hover':{
    background:'white',
  },
};
const nonActive = { borderRadius: "5px", color: "white" };

export default function CEOList({handleDrawerToggle}) {
  let location = useLocation();
  let match = useRouteMatch();
  const { pathname } = location;
  console.log("pathname", pathname)
  return (
    <div>
      <List>
        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="ceo-home" />
          )}
        >
          <ListItemButton
            sx={
              pathname === "/admin/ceo-home" || pathname === "/ceo/"
                ? active
                : nonActive
            }
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/ceo-home" || pathname === "/ceo/"
                  ? {}
                  : { color: "white" }
              }
            >
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="ceo-reports" />
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/ceo-reports" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/admin/ceo-reports" ? {} : { color: "white" }}
            >
              <PersonOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="notification" />
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/notification" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/notification"
                  ? {}
                  : { color: "white" }
              }
            >
              <NotificationAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="admin-loan-enquiry" />
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/admin-loan-enquiry" ? active : nonActive}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/admin-loan-enquiry"
                  ? {}
                  : { color: "white" }
              }
            >
              <FactCheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Enquiry" />
          </ListItemButton>
        </ListItem> */}

        {/* <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="admin-add-connector" />
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/admin-add-connector" ? active : nonActive}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/admin-add-connector"
                  ? {}
                  : { color: "white" }
              }
            >
              <PersonAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Add Connector" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="admin-settings" />
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/admin-settings" ? active : nonActive}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/admin-settings" ? {} : { color: "white" }
              }
            >
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem> */}
      </List>
    </div>
  );
}
