import { createSlice } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";
import { httpDelete, httpPost, httpPut } from "../../utils/Rest";
import { getAccountsByConnector } from '../reducers/GetLeadsDataService';
import "../../helpers";

const initialLeadsData = {
  isLoading: false,
  errorMsg: null,
  successMsg: null,
  invokeGetLeadsApi: false,
};

const slice = createSlice({
  name: "LeadsDataService", //Reference Name, It can be anything
  initialState: {
    ...initialLeadsData,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.errorMsg = null;
      state.invokeGetLeadsApi = false;
    },

    fetchError: (state, action) => {
      state.errorMsg = action?.payload;
      state.isLoading = false;
      state.invokeGetLeadsApi = false;
    },
    fetchErrorDetails: (state, action) => {
      state.errorDetails = action?.payload;
      state.isLoading = false;
      state.invokeGetLeadsApi = false;
    },
    fetchSuccess: (state, action) => {
      state.successMsg = action.payload;
      state.isLoading = false;
      state.invokeGetLeadsApi = true;
    },
    reset: (state, action) => {
      state.successMsg = initialLeadsData.successMsg;
      state.isLoading = initialLeadsData.isLoading;
      state.invokeGetLeadsApi = initialLeadsData.invokeGetLeadsApi;
      state.errorMsg = initialLeadsData.errorMsg;
    },
  },
});

export default slice.reducer;

// Actions

const { reset, fetchStart, fetchError, fetchSuccess, fetchErrorDetails } = slice.actions;

export const addNewAccountAPI = (data, file) => {
  return (dispatch) => {
    // dispatch(fetchStart());
    httpPost(`accounts`, data)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(getAccountsByConnector(localStorage.userId));
          uploadPanCard(file, res?.data?.accountId);
          dispatch(fetchSuccess("Lead added successfully"));
        } else {
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
}

export const updateExistingAccountAPI = (id, data, file) => {
  return (dispatch) => {
    // dispatch(fetchStart());
    httpPut(`accounts/${id}`, data)
      .then((res) => {
        if (res?.status === 200) {
          // uploadPanCard(file, id);
          dispatch(getAccountsByConnector(localStorage.userId));
          dispatch(fetchSuccess("Lead added successfully"));
        } else {
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
}

const uploadPanCard = (file, accountId) => {
  const data = JSON.parse(localStorage.getItem('data'));
  console.log(file, data);
  const formData = new FormData();
  formData.append('file', file);

  httpPost(`accounts/documents/${data.connectorId}/${accountId}`, formData)
    .then((res) => {
      console.log('Success');
    })
    .catch((error) => {
      console.error(error.message);
    });
}

export const deleteExistingBankAccount = (id) => {
  return (dispatch) => {
    // dispatch(fetchStart());
    httpDelete(`accounts/${id}`)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(getAccountsByConnector(localStorage.userId));
          dispatch(fetchSuccess("Lead added successfully"));
        } else {
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
}

export const postLeadsData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    httpPost(`leads`, data)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(fetchSuccess("Lead added successfully"));
        } else {
          dispatch(fetchError(res.message+(res?.issues?.join()||'')));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message+(error?.issues?.join()||'')));
      });
  };
};

export const putLeadsData = (id, data) => {
  return (dispatch) => {
    // dispatch(fetchStart());
    if (data._id) {
      delete data._id
    }
    httpPut(`leads/${id}`, data)
      .then((res) => {
        console.log("res", res.issues);
        if (res?.status === 200) {
          // dispatch(leadsData(res.data));
          dispatch(fetchSuccess("Lead data updated successfully"));
        } else {
          dispatch(fetchError(res?.message+res?.issues?.join()));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error?.message+error?.issues?.join()));
      });
  };
};


export const postConnector = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    httpPost(`connector`, data)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(fetchSuccess("Connector added successfully"));
        } else {
          dispatch(fetchErrorDetails(res.issues));
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
}

export const putConnector = (id,data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    httpPut(`connector/${id}`, data)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(fetchSuccess("Connector updated successfully"));
        } else {
          dispatch(fetchErrorDetails(res.issues));
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
}

export const resetLeads = () => {
  return (dispatch) => {
    dispatch(reset());
  };
};
