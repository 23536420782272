import { createSlice } from "@reduxjs/toolkit";
import { httpPost, httpPut } from "../../utils/Rest";

const initialLeadsData = {
    isLoading: false,
    errorMsg: null,
    successMsg: null,
    invokeGetLeadsApi: false,
};

const slice = createSlice({
    name: "LeadsDataService", //Reference Name, It can be anything
    initialState: {
        ...initialLeadsData,
    },
    reducers: {
        fetchStart: (state) => {
            state.isLoading = true;
            state.errorMsg = null;
            state.invokeGetLeadsApi = false;
        },

        fetchError: (state, action) => {
            state.errorMsg = action?.payload;
            state.isLoading = false;
            state.invokeGetLeadsApi = false;
        },
        fetchSuccess: (state, action) => {
            state.successMsg = action.payload;
            state.isLoading = false;
            state.invokeGetLeadsApi = true;
        },
        reset: (state, action) => {
            state.successMsg = initialLeadsData.successMsg;
            state.isLoading = initialLeadsData.isLoading;
            state.invokeGetLeadsApi = initialLeadsData.invokeGetLeadsApi;
            state.errorMsg = initialLeadsData.errorMsg;
        },
    },
});

export default slice.reducer;

// Actions

const { reset, fetchStart, fetchError, fetchSuccess } = slice.actions;

export const updateUserDetails = (id, data) => {
    return (dispatch) => {
        httpPost(`users/${id}`, data)
            .then((res) => {
                if (res?.status === 200) {
                    // dispatch(leadsData(res.data));
                    dispatch(fetchSuccess("Lead data updated successfully"));
                } else {
                    dispatch(fetchError(res?.message));
                }
            })
            .catch((error) => {
                dispatch(fetchError(error?.message));
            });
    };
};

// export const postLeadsData = (data) => {
//   return (dispatch) => {
//     dispatch(fetchStart());
//     httpPost(`leads`, data)
//       .then((res) => {
//         if (res?.status === 200) {
//           // dispatch(leadsData(res.data));
//           dispatch(fetchSuccess("Lead added successfully"));
//         } else {
//           dispatch(fetchError(res.message));
//         }
//       })
//       .catch((error) => {
//         dispatch(fetchError(error.message));
//       });
//   };
// };

// export const putLeadsData = (id, data) => {
//   return (dispatch) => {
//     // dispatch(fetchStart());
//     httpPut(`leads/${id}`, data)
//       .then((res) => {
//         if (res?.status === 200) {
//           // dispatch(leadsData(res.data));
//           dispatch(fetchSuccess("Lead data updated successfully"));
//         } else {
//           dispatch(fetchError(res?.message));
//         }
//       })
//       .catch((error) => {
//         dispatch(fetchError(error?.message));
//       });
//   };
// };

export const resetLeads = () => {
    return (dispatch) => {
        dispatch(reset());
    };
};
