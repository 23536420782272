import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  ErrorSnackbar,
  SuccessSnackbar
} from "../../components/CustomSnackBar";
import PageLoader from "../../components/PageLoader";
import {
  postLoginData,
  resetRedirectFlag
} from "../../redux/reducers/Auth/LoginService";
import useAppContext from "../../utils/useAppContext";
import BannerLeft from "./BannerLeft";
import logo from "./Logo.svg";
import Impetus from "./Impetus.jpeg";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © Digi Loans"} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object({
  email: yup.string().email().required("Email is Required"),
  password: yup.string().min(8).max(32).required("Password is Required"),
});

export default function LoginPage() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAppContext();

  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver,
    // defaultValues: { email: "mkhati@gmail.com", password: "aaBB11##" },
    // defaultValues: { email: "vadlasuresh_mbnr@yahoo.com", password: "aaBB11##" }, 
    // defaultValues: { email: "venkat@starpowerz.com", password: "aaBB11##" },
    // defaultValues: { email: "rajesh@starpowerz.com", password: "aaBB11##" },
  });

  const dispatch = useDispatch();

  const { loginData, isLoading, successMsg, errorMsg, redirectFlag } =
    useSelector(({ loginService }) => loginService);

  const onSubmitHandler = (data) => {
    // console.log(data, ":data");
    dispatch(postLoginData(data));
    // reset();
    // auth.signin(() => {
    //   history.replace(from);
    // });
  };

  useEffect(() => {
    reset();
    if (redirectFlag) {
      setTimeout(() => dispatch(resetRedirectFlag()), 100);
      auth.signin(() => {
        history.push("/");
      }, loginData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData, redirectFlag]);

  let { from } = location.state || { from: { pathname: "/" } };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={0} alignItems="center" style={{ backgroundColor: '#ffffff' ,height:'100vh'}}>
        {/* <BannerLeft /> */}
        <Grid item xs={12} sm={12} style={{ padding: 10 }}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 3,
              textAlign: 'left',
              padding: '20px',
              margin: '20px',
              alignItems: 'baseline',
              maxWidth: '400px',
              margin: 'auto'
            }}
          >
            <img
              style={{ maxHeight: "40px", margin: '8px 0' }}
              src={Impetus}
            />
            <Typography
              as="h1"
              variant="h5"
              sx={{ my: 2, fontWeight: "500" }}
            >
              Sign In
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
              sx={{ width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                variant="standard"
                {...register("email")}
              />
              <p>{errors.email?.message}</p>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="standard"
                {...register("password")}
              />
              <p>{errors.password?.message}</p>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    {...register("remember")}
                    // color="primary"
                  />
                }
                label="Remember me"
              /> */}

              <Controller
                name="remember"
                control={control}
                defaultValue={false}
                render={({ field }) => <Checkbox {...field}
                  sx={{
                    paddingLeft: 0
                  }}
                />}
              />
              <FormLabel
                htmlFor="remember"
                color="primary"
                sx={{ lineHeight: 1.5, marginTop: 1 }}
              >
                Remember me
              </FormLabel>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#4DD0E1" }}
              >
                Login
              </Button>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Link href="/reset-pass" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 2, mb: 2 }} /> */}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Grid container component="main" sx={{ height: "auto", backgroundColor: 'white' }}>
        <CssBaseline />
        <div className="login-page">
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            lg={6.5}
            sx={
              {
                // backgroundImage: "url(https://source.unsplash.com/random)",
                // backgroundRepeat: "no-repeat",
                // backgroundColor: (t) =>
                //   t.palette.mode === "light"
                //     ? t.palette.grey[50]
                //     : t.palette.grey[900],
                // backgroundSize: "cover",
                // backgroundPosition: "center",
              }
            }
          >

          </Grid>

          <Grid
            sx={{
              background: "blue",
              boxShadow: "none"
            }}

            xs={12}
            sm={8}
            md={5}
            lg={5.5}
            component={Paper}


          >

          </Grid>
        </div>

      </Grid>
      {isLoading && <PageLoader />}
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
    </ThemeProvider>
  );
}
