import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Register.scss";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { ApiUrl } from "../../helpers/getUrl";
import axios from "axios";
import queryString from "query-string";
import logo from "../login/Logo.svg";
import Impetus from "../login/Impetus.jpeg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import BannerLeft from "../login/BannerLeft";

export default function Register(props) {
  let history = useHistory();
  const [message, setMessage] = useState(null);
  const [mounted, setMounted] = useState(true);
  const [user, setUser] = useState({
    email: "",
    registrationCode: "",
    _id: ""
  });

  const defaultValues = {
    newPassword: "",
    confirmNewPassword: "",
    userEmail: user.email,
  };
  const request = queryString.parse(props.location.search);
  console.log("queryString", request.code);

  const validationSchema = yup.object({
    userEmail: yup.string().required("Registred Email is required"),
    newPassword: yup
      .string()
      .min(8, "Must be at least 8 characters")
      .max(32)
      .required("New Password is required"),
    confirmNewPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });

  const resolver = yupResolver(validationSchema);

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (mounted) {
      axios.get(`${ApiUrl()}auth/${request.code}`).then((res) => {
        console.log("res", res.data);
        setUser(res.data);
        setValue("userEmail", res.data.email);
        setMounted(false);
      });
    }
    return () => {
    }
  }, [mounted]);

  //console.log("email", user, user.email, register("userEmail"),watch("userEmail"));

  const submitRegister = (payload) => {
    let { userEmail, newPassword, confirmNewPassword } = payload;

    if (newPassword !== confirmNewPassword) {
      setMessage({
        severity: "error",
        title: "Error",
        description: "Passwords didn't matched",
      });
      return;
    }

    axios
      .put(`${ApiUrl()}auth/${request.code}`, {
        password: confirmNewPassword,
      })
      .then((res) => {
        console.log(res);
        //   setgetConnectorDetails(true);
        setMessage({
          severity: "success",
          title: "Password Set Request",
          description: "Password sets successfully.",
        });
        setTimeout(() => {
          history.push("/");
        }, 2000);
        //   reset();
      })
      .catch((error) => {
        setMessage({
          severity: "error",
          title: "Error",
          description: error.response.data.message,
        });
      });
  };

  return (
    <div class={"Register"}>
      {message && (
        <Alert
          severity={message.severity}
          onClose={() => {
            setMessage(null);
          }}
        >
          <AlertTitle>{message.title}</AlertTitle>
          {message.description}
        </Alert>
      )}
      {/* <div class={"row"}> */}
        {/* <div class={"col"}></div> */}
        <Grid container spacing={0} alignItems="center" style={{ backgroundColor: '#ffffff' }}>
          {/* <BannerLeft /> */}
          <Grid item xs={12} sm={12} style={{ padding: 10 }}>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: 3,
                textAlign: 'left',
                padding: '20px',
                margin: '20px',
                alignItems: 'baseline',
                maxWidth: '400px',
                margin: 'auto'
              }}
            >
              {/* <div class={"card col-4 text-center"}> */}
                <img
                  style={{ maxHeight: "40px", margin: '8px 0' }}
                  src={Impetus}
                />
                <Typography
                  as="h1"
                  variant="h5"
                  sx={{ my: 2, fontWeight: "500" }}
                >
                  Register
                </Typography>
                {/* <img src={Logo} alt="logo" style={{ padding: "20px" }} /> */}
                <form
                  className="row form-fields mt-md-n5"
                  onSubmit={handleSubmit(submitRegister)}
                >
                  {/* <div className="col-md-12"> */}
                  <FormControl variant="standard" className={""}>
                    <TextField
                      className={""}
                      margin="dense"
                      {...register("userEmail")}
                      label="Registered Email"
                      fullWidth
                      variant="standard"
                      value={user.email}
                      error={errors?.userEmail && errors?.userEmail}
                      helperText={errors?.userEmail?.message}
                      disabled
                    />
                  </FormControl>
                  {/* </div>
            <div className="col-md-12"> */}
                  <FormControl variant="standard" className={""}>
                    <TextField
                      className={""}
                      margin="dense"
                      {...register("newPassword")}
                      label="New Password"
                      fullWidth
                      variant="standard"
                      type="password"
                      defaultValue={watch("newPassword")}
                      error={errors?.newPassword && errors?.newPassword}
                      helperText={errors?.newPassword?.message}
                    />
                  </FormControl>
                  {/* </div> */}
                  {/* <div className="col-md-12"> */}
                  <FormControl variant="standard" className={""}>
                    <TextField
                      className={""}
                      margin="dense"
                      {...register("confirmNewPassword")}
                      label="Confirm New Password"
                      fullWidth
                      variant="standard"
                      type="password"
                      defaultValue={watch("confirmNewPassword")}
                      error={
                        errors?.confirmNewPassword && errors?.confirmNewPassword
                      }
                      helperText={errors?.confirmNewPassword?.message}
                    />
                  </FormControl>
                  {/* </div> */}
                  {/* <div class={"col-md-12 text-center register-btn"}> */}
                  <Button
                    variant="contained"
                    type="submit"
                  // disable={isLoading ? "true" : "false"}
                  >
                    Register
                  </Button>
                  {/* </div> */}
                </form>
              {/* </div> */}
            </Paper>
          </Grid>
          </Grid>

          {/* <div class={"col"}></div> */}
      {/* </div> */}
    </div>
  );
}
