import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useContext } from "react";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import { productConstants, timeIntervalConstants } from "../../constants/appconstants";

const TopButtons = (props) => {
  const { LTD, YTD, HTD, QTD, MTD } = timeIntervalConstants;
  const { freqValue, changeFreqValue, productType, changeProductType } = useContext(AppContextProvider);

  const filters = [
    { label: 'Current Month', value: MTD },
    { label: 'Last 3 Months', value: QTD },
    { label: 'Last 6 Months', value: HTD },
    { label: 'Year to Date', value: YTD },
    { label: 'Lifetime Till Date', value: LTD },
  ]

  const UpdateActiveButton = (freq) => {
    changeFreqValue(freq);
  };

  const handleChange = (event) => {
    changeProductType(event.target.value);
  };

  const handleFrequencyChange = (event) => {
    changeFreqValue(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={8} lg={8} sx={{ display: { xs: 'none', md: 'block' } }} >
        {filters.map((filter, index) => <button className={freqValue === filter.value ? "buttons-tabs button-active" : "buttons-tabs"}
          onClick={(e) => UpdateActiveButton(e.target.value)}
          value={filter.value}
        >
          {filter.label}
        </button>)}
      </Grid>
      <Grid item xs={6} sm={6} sx={{ display: { md: 'none' } }} >
        <FormControl sx={{
          width: '100%',
          maxWidth: 280,
          backgroundColor: "#fff",
          border: 0,
          outline: 0,
        }}>
          <Select
            value={freqValue}
            onChange={handleFrequencyChange}
          >
            {filters.map((filter, index) => <MenuItem value={filter.value}>{filter.label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={4}>
        <FormControl
          sx={{
            width: '100%',
            maxWidth: 280,
            backgroundColor: "#fff",
            border: 0,
            outline: 0,
          }}
          style={{ float: "right" }}
        >
          <Select
            value={productType}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={""}>All Products</MenuItem>
            {Object.keys(productConstants).map((i) => (
              <MenuItem value={i} key={i}>{productConstants[i]}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default React.memo(TopButtons);
