import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiUrl } from '../../../helpers/getUrl';
import { httpPost } from "../../../utils/Rest";

const initialLeadsData = {
  loginData: {},
  isLoading: false,
  errorMsg: (localStorage.errorMsg||null),
  successMsg: null,
  redirectFlag: false,
};

const slice = createSlice({
  name: "LoginService", //Reference Name, It can be anything
  initialState: {
    ...initialLeadsData,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.errorMsg = null;
      localStorage.setItem("errorMsg", state.errorMsg||'');
    },
    loginData: (state, action) => {
      state.loginData = action.payload;
      state.isLoading = false;
      state.errorMsg = null;
      state.redirectFlag = true;
    },
    fetchError: (state, action) => {
      state.errorMsg = action?.payload;
      localStorage.setItem("errorMsg", state.errorMsg);
      state.isLoading = false;
    },
    fetchSuccess: (state, action) => {
      state.successMsg = action.payload;
      state.isLoading = false;
    },
    resetFlag: (state, action) => {
      state.redirectFlag = false;
    },
  },
});

export default slice.reducer;

// Actions

const { loginData, fetchStart, fetchError, fetchSuccess, resetFlag } =
  slice.actions;

export const postLoginData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    httpPost(`auth`, data)
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem('user', JSON.stringify(res.data));
          const {userRole, userId} = res.data
          getId(userRole.replace(/_/g, ""), userId);
          dispatch(loginData(res?.data));
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

const getId = ( role, userId) => {
  axios.get(`${ApiUrl()}${role}/user/${userId}`)
    .then(res => {
      localStorage.setItem('data', JSON.stringify(res.data));
    }).catch(error => {
      console.error('There was an error!', error);
    });
}

export const resetRedirectFlag = () => {
  return (dispatch) => {
    dispatch(resetFlag());
  };
};
