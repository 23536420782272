import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Typography from '@mui/material/Typography';
import axios from "axios";
import { default as React, useState } from 'react';
import { ErrorSnackbar, SuccessSnackbar } from "../../components/CustomSnackBar";
import { ApiUrl } from "../../helpers/getUrl";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function BulkUpload({ type,disabled }) {
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notUpdatedApplications, setnotUpdatedApplications] = useState([])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setnotUpdatedApplications([]);
        setOpen(false);
        setSelectedFile(null);
        setIsFilePicked(false);
    };

    const changeHandler = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setIsFilePicked(true);
    };

    const handleSubmission = () => {
        setLoading(true);
        const localStorageData = JSON.parse(localStorage.getItem('data'));

        const formData = new FormData();
        formData.append('file', selectedFile);

        const path = type === 'CONNECTOR_FINADMIN' ? `connector/upload/${localStorageData.finadminId}` : 'leads/admin/upload';

        axios.post(`${ApiUrl()}${path}`, formData)
            .then(res => {
                setSelectedFile(null);
                setIsFilePicked(false);
                setLoading(false);
                let notupdated = res.data.notUpdatedApplications;
                let updated = res.data.updatedApplications;
                if (notupdated && notupdated.length) {
                    let message = `${notupdated.length} records are failed to update thoes are ${notupdated.join(',')} and `
                    if (updated && updated.length) {
                        message += `${updated.length} records are updated successfully`;    
                    }
                    //console.log("setnotUpdatedApplications", notupdated);
                    setnotUpdatedApplications(notupdated);
                    //setSuccessMessage(message);
                } else {
                    setOpen(false);
                    setSuccessMessage(`${updated.length} records are updated successfully`);
                }
            }).catch(error => {
                setErrorMessage(error.message);
                setOpen(false);
                setSelectedFile(null);
                setIsFilePicked(false);
                setLoading(false);
            });
    };

    return (
        <div>
            <Button
                sx={{ boxShadow: 2 }}
                variant="text"
                endIcon={<FileUploadOutlinedIcon />}
                onClick={handleClickOpen}
                disabled={disabled||false}
            >Bulk Upload</Button>
            <BootstrapDialog
                fullWidth={true}
                maxWidth="xs"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>Bulk Upload</BootstrapDialogTitle>
                <form>
                    <DialogContent dividers>
                        <FormControl variant="standard">
                            {isFilePicked && <Typography variant="h6" gutterBottom component="div">
                                {selectedFile?.name}
                            </Typography>}
                            <label htmlFor="contained-button-file">
                                <input style={{ display: 'none' }} id="contained-button-file" type="file" onChange={changeHandler} />
                                <Button variant='outlined' component='span'>
                                    choose file here
                                </Button>
                            </label>
                        </FormControl>
                        
                        {(notUpdatedApplications.length || '') && <div style={{maxHeight:'300px',overflow:'auto'}}>
                            <table className="table table-striped">
                            <thead>
                                <tr><th style={{color:'red'}}>Not Updated Application Numbers</th></tr>
                            </thead>
                            <tbody>
                                {notUpdatedApplications.map(v => <tr><td>{v}</td></tr>)}
                            </tbody>
                            </table>
                        </div>}
                    </DialogContent>
                </form>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleSubmission} disabled={!isFilePicked || loading}>
                        <FileUploadOutlinedIcon />
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            {errorMessage && <ErrorSnackbar errorMsges={errorMessage} />}
            {successMessage && <SuccessSnackbar successMsg={successMessage} />}
        </div>
    )
}