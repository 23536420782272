export const timeIntervalConstants = {
  LTD: "LTD",
  YTD: "YTD",
  HTD: "HTD",
  QTD: "QTD",
  MTD: "MTD",
};

export const timeIntervalLabels = {
  MTD: "Current Month",
  QTD: "Last 3 Months",
  HTD: "Last 6 Months",
  YTD: "Year To Date",
  LTD: "LTD",
};

export const productConstants = {
  // ALL: "",
  PERS: "Personal Loan",
  AUTO: "Auto Loan",
  HOME: "Home Loan",
  BUSI: "Business Loan",
  GOLD: "Gold Loan",
  INSR: "Insurance",
  CRED: "Credit Card",
  EDUL: "Education Loan",
};

export const stateConstants = {
  AN: "Andaman and Nicobar Islands",
  AP: "Andhra Pradesh",
  AR: "Arunachal Pradesh",
  AS: "Assam",
  BR: "Bihar",
  CG: "Chandigarh",
  CH: "Chhattisgarh",
  DN: "Dadra and Nagar Haveli",
  DD: "Daman and Diu",
  DL: "Delhi",
  GA: "Goa",
  GJ: "Gujarat",
  HR: "Haryana",
  HP: "Himachal Pradesh",
  JK: "Jammu and Kashmir",
  JH: "Jharkhand",
  KA: "Karnataka",
  KL: "Kerala",
  LA: "Ladakh",
  LD: "Lakshadweep",
  MP: "Madhya Pradesh",
  MH: "Maharashtra",
  MN: "Manipur",
  ML: "Meghalaya",
  MZ: "Mizoram",
  NL: "Nagaland",
  OR: "Odisha",
  PY: "Puducherry",
  PB: "Punjab",
  RJ: "Rajasthan",
  SK: "Sikkim",
  TN: "Tamil Nadu",
  TS: "Telangana",
  TR: "Tripura",
  UP: "Uttar Pradesh",
  UK: "Uttarakhand",
  WB: "West Bengal",
};

export const stateNameToCodeMapping = {
  "Andaman and Nicobar Islands": "AN",
  "Andhra Pradesh": "AP",
  "Arunachal Pradesh": "AR",
  "Assam": "AS",
  "Bihar": "BR",
  "Chandigarh": "CG",
  "Chhattisgarh": "CH",
  "Dadra and Nagar Haveli": "DN",
  "Daman and Diu": "DD",
  "Delhi": "DL",
  "Goa": "GA",
  "Gujarat": "GJ",
  "Haryana": "HR",
  "Himachal Pradesh": "HP",
  "Jammu and Kashmir": "JK",
  "Jharkhand": "JH",
  "Karnataka": "KA",
  "Kerala": "KL",
  "Ladakh": "LA",
  "Lakshadweep": "LD",
  "Madhya Pradesh": "MP",
  "Maharashtra": "MH",
  "Manipur": "MN",
  "Meghalaya": "ML",
  "Mizoram": "MZ",
  "Nagaland": "NL",
  "Odisha": "OR",
  "Puducherry": "PY",
  "Punjab": "PB",
  "Rajasthan": "RJ",
  "Sikkim": "SK",
  "Tamil Nadu": "TN",
  "Telangana": "TS",
  "Tripura": "TR",
  "Uttar Pradesh": "UP",
  "Uttarakhand": "UK",
  "West Bengal": "WB"
}

export const bankNameConstants = {
  "AXIS BANK": "AXIS",
  "BANK OF AMERICA": "BOA",
  "BANK OF INDIA": "BOI",
  "YES BANK": "YESBANK",
  "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED": "APCOB",
  "THE NASIK MERCHANTS COOPERATIVE BANK LIMITED": "NAMCOBANK",
  "APNA SAHAKARI BANK LIMITED": "APNABANK",
  "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED": "ANZ",
  "CAPITAL SMALL FINANCE BANK LIMITED": "CAPITALBANK",
  "BANK OF MAHARASHTRA": "BOM",
  "JALGAON JANATA SAHAKARI BANK LIMITED": "JJSBL",
  "JANATA SAHAKARI BANK LIMITED": "JANATABANK",
  "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED": "IJSBANK",
  "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED": "MDCCBANK",
  "PRIME COOPERATIVE BANK LIMITED": "PRIMEBANK",
  "THE THANE BHARAT SAHAKARI BANK LIMITED": "THANEBHARATBANK",
  "THE A.P. MAHESH COOPERATIVE URBAN BANK LIMITED": "APMAHESHBANK",
  "BANK OF TOKYO MITSUBISHI LIMITED": "MUFG",
  "THE GUJARAT STATE COOPERATIVE BANK LIMITED": "GSCBANK",
  "KARNATAKA VIKAS GRAMEENA BANK": "KVGBANK",
  "THE MUNICIPAL COOPERATIVE BANK LIMITED": "MUNICIPALBANK",
  "NAGPUR NAGARIK SAHAKARI BANK LIMITED": "NNSBANK",
  "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED": "KCCB",
  "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED": "RSCB",
  "THE SURAT DISTRICT COOPERATIVE BANK LIMITED": "SUDICOBANK",
  "THE VISHWESHWAR SAHAKARI BANK LIMITED": "VISHWESHWARBANK",
  "WOORI BANK": "WOORIBANK",
  "SUTEX COOPERATIVE BANK LIMITED": "SUTEXBANK",
  "BARCLAYS BANK": "BARCLAYS",
  "GURGAON GRAMIN BANK": "SHGB",
  "COMMONWEALTH BANK OF AUSTRALIA": "COMMBANK",
  "PRATHAMA BANK": "PRATHAMABANK",
  "NORTH MALABAR GRAMIN BANK": "KERALAGBANK",
  "THE VARACHHA COOPERATIVE BANK LIMITED": "VARACHHABANK",
  "SBER BANK": "SBERBANK",
  "TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED": "TGMCBANK",
  "VASAI VIKAS SAHAKARI BANK LIMITED": "VASAIVIKASBANK",
  "VASAI VIKAS SAHAKARI BANK LTD": "VASAIVIKASBANK",
  "BASSEIN CATHOLIC COOPERATIVE BANK LIMITED": "BCCB",
  "WESTPAC BANKING CORPORATION": "WESTPAC",
  "ANDHRA PRAGATHI GRAMEENA BANK": "APGB",
  "SUMITOMO MITSUI BANKING CORPORATION": "SMBC",
  "THE SEVA VIKAS COOPERATIVE BANK LIMITED": "SEVAVIKASBANK",
  "THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED": "THANEDISTRICTBANK",
  "JP MORGAN BANK": "JPMORGAN",
  "THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED": "GDCCBANK",
  "THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK": "AKOLADCC",
  "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED": "KURMANCHALBANK",
  "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED": "JPCBANK",
  "PARIBAS": "BNPPARIBAS",
  "NATIONAL AUSTRALIA BANK LIMITED": "NAB",
  "SAHEBRAO DESHMUKH COOPERATIVE BANK LIMITED": "SDCBANK",
  "BANK INTERNASIONAL INDONESIA": "MAYBANK",
  "SOLAPUR JANATA SAHAKARI BANK LIMITED": "SJSBBANK",
  "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED": "ICBC",
  "UNITED OVERSEAS BANK LIMITED": "UOB",
  "ZILA SAHAKRI BANK LIMITED GHAZIABAD": "ZSBL",
  "JANASEVA SAHAKARI BANK BORIVLI LIMITED": "JANASEVABANK",
  "RAJGURUNAGAR SAHAKARI BANK LIMITED": "RAJGURUNAGARBANK",
  "CANARA BANK": "CANARABANK",
  "NAGAR URBAN CO OPERATIVE BANK": "NUCB",
  "AKOLA JANATA COMMERCIAL COOPERATIVE BANK": "AKOLAJANATABANK",
  "BHARATIYA MAHILA BANK LIMITED": "BMB",
  "HSBC BANK OMAN SAOG": "HSBC",
  "THE KANGRA COOPERATIVE BANK LIMITED": "KANGRABANK",
  "THE ZOROASTRIAN COOPERATIVE BANK LIMITED": "ZOROASTRIANBANK",
  "SHIKSHAK SAHAKARI BANK LIMITED": "SHIKSHAKBANK",
  "THE HASTI COOP BANK LTD": "HASTIBANK",
  "CATHOLIC SYRIAN BANK LIMITED": "CSB",
  "KERALA GRAMIN BANK": "KERALAGBANK",
  "PRAGATHI KRISHNA GRAMIN BANK": "PRAGATHIKRISHNABANK",
  "DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION": "DICGC",
  "DEVELOPMENT BANK OF SINGAPORE": "DBS",
  "DOHA BANK": "DOHABANK",
  "DOHA BANK QSC": "DOHABANK",
  "EXPORT IMPORT BANK OF INDIA": "EXIMBANK",
  "JANAKALYAN SAHAKARI BANK LIMITED": "JKSBL",
  "TJSB SAHAKARI BANK LIMITED": "TJSBBANK",
  "TJSB SAHAKARI BANK LTD": "TJSBBANK",
  "THE COSMOS CO OPERATIVE BANK LIMITED": "COSMOSBANK",
  "SURAT NATIONAL COOPERATIVE BANK LIMITED": "SURATNATIONALBANK",
  "CENTRAL BANK OF INDIA": "CENTRALBANK",
  "IDFC BANK LIMITED": "IDFC",
  "INDUSTRIAL BANK OF KOREA": "IBK",
  "SBM BANK MAURITIUS LIMITED": "SBM",
  "NATIONAL BANK OF ABU DHABI PJSC": "NBAD",
  "KEB HANA BANK": "KEBHANA",
  "THE PANDHARPUR URBAN CO OP. BANK LTD. PANDHARPUR": "PANDHARPURBANK",
  "SAMARTH SAHAKARI BANK LTD": "SAMARTHBANK",
  "SHIVALIK MERCANTILE CO OPERATIVE BANK LTD": "SHIVALIKBANK",
  "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD": "HPSCB",
  "DEOGIRI NAGARI SAHAKARI BANK LTD. AURANGABAD": "DEOGIRIBANK",
  "CHINATRUST COMMERCIAL BANK LIMITED": "CHINATRUST",
  "PT BANK MAYBANK INDONESIA TBK": "MAYBANK",
  "MAHARASHTRA GRAMIN BANK": "MAHAGRAMIN",
  "EQUITAS SMALL FINANCE BANK LIMITED": "EQUITAS",
  "AIRTEL PAYMENTS BANK LIMITED": "AIRTEL",
  "CITI BANK": "CITIBANK",
  "CITIZEN CREDIT COOPERATIVE BANK LIMITED": "CITIZENCREDITBANK",
  "CITY UNION BANK LIMITED": "CUB",
  "CORPORATION BANK": "CORPBANK",
  "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK CALYON BANK": "CACIB",
  "DENA BANK": "DENABANK",
  "DEUSTCHE BANK": "DB",
  "DCB BANK LIMITED": "DCB",
  "DHANALAKSHMI BANK": "DHANBANK",
  "DOMBIVLI NAGARI SAHAKARI BANK LIMITED": "DNSBANK",
  "FIRSTRAND BANK LIMITED": "FIRSTRAND",
  "HDFC BANK": "HDFC",
  "HSBC BANK": "HSBC",
  "ICICI BANK LIMITED": "ICICI",
  "IDBI BANK": "IDBI",
  "INDIAN BANK": "INDIANBANK",
  "INDIAN OVERSEAS BANK": "IOB",
  "INDUSIND BANK": "INDUSIND",
  "JANASEVA SAHAKARI BANK LIMITED": "JANASEVABANK",
  "KAPOL COOPERATIVE BANK LIMITED": "KAPOLBANK",
  "KARNATAKA BANK LIMITED": "KARNATAKABANK",
  "KARUR VYSYA BANK": "KVB",
  "KOTAK MAHINDRA BANK LIMITED": "KOTAK",
  "MAHANAGAR COOPERATIVE BANK": "MAHANAGARBANK",
  "MAHARASHTRA STATE COOPERATIVE BANK": "MSCBANK",
  "MASHREQBANK PSC": "MASHREQBANK",
  "MIZUHO BANK LTD": "MIZUHOBANK",
  "NEW INDIA COOPERATIVE BANK LIMITED": "NEWINDIABANK",
  "NKGSB COOPERATIVE BANK LIMITED": "NKGSB",
  "NUTAN NAGARIK SAHAKARI BANK LIMITED": "NUTANBANK",
  "ORIENTAL BANK OF COMMERCE": "OBC",
  "PARSIK BANK": "GPPARSIKBANK",
  "PUNJAB AND MAHARSHTRA COOPERATIVE BANK": "PMC",
  "PUNJAB AND SIND BANK": "PSB",
  "PUNJAB NATIONAL BANK": "PNB",
  "RAJKOT NAGRIK SAHAKARI BANK LIMITED": "RNSB",
  "RESERVE BANK OF INDIA": "RBI",
  "SHINHAN BANK": "SHINHAN",
  "SOCIETE GENERALE": "SOCIETEGENERALE",
  "SOUTH INDIAN BANK": "SOUTHINDIANBANK",
  "STANDARD CHARTERED BANK": "SC",
  "STATE BANK OF BIKANER AND JAIPUR": "SBBJ",
  "STATE BANK OF HYDERABAD": "SBHYD",
  "STATE BANK OF INDIA": "SBI",
  "STATE BANK OF MAURITIUS LIMITED": "SBMGROUP",
  "STATE BANK OF MYSORE": "SBM",
  "STATE BANK OF PATIALA": "SBP",
  "STATE BANK OF TRAVANCORE": "SBT",
  "SYNDICATE BANK": "SYNDICATEBANK",
  "TAMILNAD MERCANTILE BANK LIMITED": "TMBL",
  "THE BANK OF NOVA SCOTIA": "SCOTIABANK",
  "AHMEDABAD MERCANTILE COOPERATIVE BANK": "AMCOBANK",
  "BHARAT COOPERATIVE BANK MUMBAI LIMITED": "BHARATBANK",
  "FEDERAL BANK": "FEDERALBANK",
  "THE GREATER BOMBAY COOPERATIVE BANK LIMITED": "GREATERBANK",
  "JAMMU AND KASHMIR BANK LIMITED": "JKBANK",
  "KALUPUR COMMERCIAL COOPERATIVE BANK": "KALUPURBANK",
  "THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED": "KARNATAKAAPEX",
  "KALYAN JANATA SAHAKARI BANK": "KALYANJANATA",
  "LAXMI VILAS BANK": "LVB",
  "THE MEHSANA URBAN COOPERATIVE BANK": "MUCBANK",
  "THE NAINITAL BANK LIMITED": "NAINITALBANK",
  "RBL BANK LIMITED": "RBL",
  "THE ROYAL BANK OF SCOTLAND": "RBS",
  "SARASWAT COOPERATIVE BANK LIMITED": "SARASWATBANK",
  "THE SHAMRAO VITHAL COOPERATIVE BANK": "SVCBANK",
  "THE SURATH PEOPLES COOPERATIVE BANK LIMITED": "SPCBL",
  "THE TAMIL NADU STATE APEX COOPERATIVE BANK": "TNSCBANK",
  "THE WEST BENGAL STATE COOPERATIVE BANK": "WBSCB",
  "UCO BANK": "UCOBANK",
  "UNION BANK OF INDIA": "UNIONBANK",
  "UNITED BANK OF INDIA": "UNITEDBANK",
  "VIJAYA BANK": "VIJAYABANK",
};

export const bankNameConstantsNationalised ={
  "AADHAAR HL":"AADHAARHL",
  "ADITYA BIRLA":"ADITYABIRLA",
  "AROHAN FINANCE":"AROHANFINANCE",
  "ASHV":"ASHV",
  "ASPIRE HL":"ASPIREHL",
  "AXIS BANK": "AXIS",
  "AXIS FINANCE":"AXISFINANCE",
  "BANDHAN BANK": "BANDHANBANK",
  "BANK OF BARODA":"BOB",
  "BANK OF BARODA HL": "BOBHL",
  "BANK OF INDIA": "BOI",
  "BANK OF MAHARASHTRA": "BOM",
  "BAJAJ":"BAJAJ",
  "BAJAJ ALLIANZ LIFE INSURANCE":"BALI",
  "BHARTI AXA LIFE INSURANCE":"BHARTI",
  "CANARA BANK": "CANARABANK",
  "CAPITAL FLOAT":"CAPITALFLOAT",
  "CARE HEALTH INSURANCE COMPANY":"CHIC",
  "CENTRAL BANK OF INDIA": "CENTRALBANK",
  "CENTRUM FINANCIAL":"CENTRUMFINANCIAL",
  "CHOLAMANDALAM HL":"CHOLAMANDALAMHL",
  "CITI BANK": "CITIBANK",
  "CLIX":"CLIX",
  "DCB BANK HL":"DCBBANKHL",
  "DEUTSCHE BANK":"DEUTSCHEBANK",
  "EDELWEISS BANKING":"EDELWEISSBANKING",
  "EQUITAS LAP":"EQUITASLAP",
  "FEDERAL BANK": "FEDERALBANK",
  "FINNABLE":"FINNABLE",
  "FINPLEX":"FINPLEX",
  "FINZY":"FINZY",
  "FULLERTON":"FULLERTON",
  "FUTURE GENERALI LIFE INSURANCE":"FGLI",
  "GROWTH SOURCE":"GROWTHSOURCE",
  "HDFC BANK": "HDFC",
  "HERO FINCORP":"HEROFINCORP",
  "HOME FIRST FINANCE COMPANY HL":"HFFCHL",
  "ICICI  BANK LIMITED": "ICICI",
  "IDFC FIRST BANK":"IDFC",
  "IIFL HL":"IIFLHL",
  "IKF FINANCE  HL & LAP":"IKF",
  "INCRED":"INCRED",
  "INDIABULLS HL":"INDIABULLSHL",
  "INDIA FIRST LIFE INSURANCE":"IFLI",
  "INDIA SHELTER HL":"INDIASHELTERHL",
  "INDIAN BANK": "INDIANBANK",
  "INDIAN OVERSEAS BANK": "IOB",
  "INDIFI FINANCE":"INDIFIFINANCE", 
  "INDITRADE":"INDITRADE",
  "INDOSTAR LAP":"INDOSTARLAP",
  "INDUSIND BANK": "INDUSIND",
  "KAPITAL TECH ":"KAPITALTECH",
  "KOTAK MAHINDRA BANK LIMITED": "KOTAK",
  "KVB BANK HL":"KVBBANKHL",
  "LIC HOUSING HL":"LICHOUSINGHL",
  "L & T FINANCE HL":"LTFINANCEHL",
  "LENDING KART":"LENDINGKART",
  "LOANTAP":"LOANTAP",
  "MAX BUPA HEALTH INSURANCE":"MBHI",
  "MAXLIFE INSURANCE COMPANY":"MIC",
  "MOTILAL HL":"MOTILALHL",
  "MUTHOOT":"MUTHOOT",
  "MYSHUBLIFE":"MYSHUBLIFE",
  "NCML FINANCE PRIVATE LIMITED":"NCML",
  "NEOGROWTH RENEWAL CASES":"NRC",
  "OXYZO FINANCIAL SERVICES PRIVATE LIMITED":"OXYZO",
  "PAYSENSE": "PAYSENSE",
  "PIRAMAL HL":"PIRAMALHL",
  "PNB HOUSING HL":"PNBHOUSINGHL",
  "POONAWALLA":"POONAWALLA",
  "PROFECTUS CAPITAL LAP":"PROFECTUSCAPITALLAP",
  "PUNJAB AND SIND BANK": "PSB",
  "PUNJAB NATIONAL BANK": "PNB",
  "RBL BANK LAP":"RBLBANKLAP",
  "RELIANCE GENERAL INSURANCE":"RGI",
  "RELIANCE LIFE INSURANCE COMPANY":"RLIC",
  "ROYAL SUNDARAM":"ROYALSUNDARAM",
  "RUPEEK FRESH LOAN":"RUPEEKFRESHLOAN",
  "SBI CAP":"SBICAP",
  "STANDARD CHARTERED BANK":"SCB",
  "SHINHAN BANK HL":"SHINHANBANKHL",
  "SHRIRAM CITY LAP": "SHRIRAMCITYLAP",
  "SME CORNOR":"SMECORNOR",
  "SOUTH INDIAN BANK":"SIB",
  "STATE BANK OF INDIA": "SBI",
  "STAR HEALTH INSURANCE": "SHI",
  "SUNDHARAM BNP PARIBHAS HOME FINANCE LTD":"SUNDHARAM",
  "TATA CAPITAL":"TATACAPITAL",
  "THE NAINITAL BANK LIMITED": "NAINITALBANK",
  "U GRO": "UGRO",
  "UJJIVAN":"UJJIVAN",
  "UNION BANK OF INDIA": "UNIONBANK",
  "UCO BANK": "UCOBANK",
  "VASTU FINANCE LAP":"VASTUFINANCELAP",
  "VISTAAR HL":"VISTAARHL",
  "YES BANK": "YESBANK",
}

export const bankCodeConstants = {
  "AADHAARHL":"AADHAAR HL",
  "ADITYABIRLA":"ADITYA BIRLA",
  "AROHANFINANCE":"AROHAN FINANCE",
  "ASHV":"ASHV",
  "ASPIREHL":"ASPIRE HL",
  "AXIS": "AXIS BANK",
  "AXISFINANCE":"AXIS FINANCE",
  "BOA": "BANK OF AMERICA",
  "BOI": "BANK OF INDIA",
  "BOB":"BANK OF BARODA",
  "BOBHL": "BANK OF BARODA HL",
  "BAJAJ":"BAJAJ",
  "BALI":"BAJAJ ALLIANZ LIFE INSURANCE",
  "BHARTI":"BHARTI AXA LIFE INSURANCE",
  "BANDHANBANK": "BANDHAN BANK",
  "YESBANK": "YES BANK",
  "APCOB": "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED",
  "NAMCOBANK": "THE NASIK MERCHANTS COOPERATIVE BANK LIMITED",
  "APNABANK": "APNA SAHAKARI BANK LIMITED",
  "ANZ": "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED",
  "CAPITALBANK": "CAPITAL SMALL FINANCE BANK LIMITED",
  "CAPITALFLOAT":"CAPITAL FLOAT",
  "CHIC":"CARE HEALTH INSURANCE COMPANY",
  "CENTRUMFINANCIAL":"CENTRUM FINANCIAL",
  "CHOLAMANDALAMHL":"CHOLAMANDALAM HL",
  "CLIX":"CLIX",
  "DCBBANKHL":"DCB BANK HL",
  "DEUTSCHEBANK":"DEUTSCHE BANK",
  "EDELWEISSBANKING":"EDELWEISS BANKING",
  "EQUITASLAP":"EQUITAS LAP",
  "FINNABLE":"FINNABLE",
  "FINPLEX":"FINPLEX",
  "FINZY":"FINZY",
  "FULLERTON":"FULLERTON",
  "FGLI":"FUTURE GENERALI LIFE INSURANCE",
  "GROWTHSOURCE":"GROWTH SOURCE",
  "HEROFINCORP":"HEROFIN CORP",
  "HFFCHL": "HOME FIRST FINANCE COMPANY HL",
  "IDFC":"IDFC FIRST BANK",
  "IIFLHL":"IIFL HL",
  "IKF":"IKF FINANCE  HL & LAP",
  "INCRED":"INCRED",
  "INDIABULLSHL":"INDIABULLS HL",
  "IFLI":"INDIA FIRST LIFE INSURANCE",
  "INDIASHELTERHL":"INDIA SHELTER HL",
  "INDIFIFINANCE":"INDIFI FINANCE", 
  "INDITRADE":"INDITRADE",
  "INDOSTARLAP":"INDOSTAR LAP",
  "INDUSIND": "INDUSIND BANK",
  "KAPITALTECH ":"KAPITAL TECH",
  
  "KVBBANKHL":"KVB BANK HL",
  "LICHOUSINGHL":"LIC HOUSING HL",
  "LTFINANCEHL":"L & T FINANCE HL",
  "LENDINGKART":"LENDING KART",
  "LOANTAP":"LOANTAP",
  "MBHI":"MAX BUPA HEALTH INSURANCE",
  "MIC":"MAXLIFE INSURANCE COMPANY",
  "MOTILALHL":"MOTILAL HL",
  "MUTHOOT":"MUTHOOT",
  "MYSHUBLIFE":"MYSHUBLIFE",
  "NCML":"NCML FINANCE PRIVATE LIMITED",
  "NRC":"NEOGROWTH RENEWAL CASES",
  "OXYZO": "OXYZO FINANCIAL SERVICES PRIVATE LIMITED",
  "PAYSENSE": "PAYSENSE",
  "PIRAMALHL":"PIRAMAL HL",
  "PNBHOUSINGHL":"PNB HOUSING HL",
  "POONAWALLA":"POONAWALLA",
  "PROFECTUSCAPITALLAP":"PROFECTUS CAPITAL LAP",

  "RBLBANKLAP":"RBL BANK LAP",
  "RGI":"RELIANCE GENERAL INSURANCE",
  "RLIC":"RELIANCE LIFE INSURANCE COMPANY",
  "ROYALSUNDARAM":"ROYAL SUNDARAM",
  "RUPEEKFRESHLOAN":"RUPEEK FRESH LOAN",
  "SBICAP":"SBI CAP",
  "SCB":"STANDARD CHARTERED BANK",
  "SHINHANBANKHL":"SHINHAN BANK HL",
  "SHRIRAMCITYLAP": "SHRIRAM CITY LAP",
  "SMECORNOR":"SME CORNOR",
  "SIB":"SOUTH INDIAN BANK",

  "SHI":  "STAR HEALTH INSURANCE",
  "SUNDHARAM":"SUNDHARAM BNP PARIBHAS HOME FINANCE LTD",
  "TATACAPITAL":"TATA CAPITAL",
  "NAINITALBANK": "THE NAINITAL BANK LIMITED",
  "UGRO": "U GRO",
  "UJJIVAN":"UJJIVAN",

  "VASTUFINANCELAP":"VASTU FINANCE LAP",
  "VISTAARHL":"VISTAAR HL",
  "BOM": "BANK OF MAHARASHTRA",
  "JJSBL": "JALGAON JANATA SAHAKARI BANK LIMITED",
  "JANATABANK": "JANATA SAHAKARI BANK LIMITED",
  "IJSBANK": "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED",
  "MDCCBANK": "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
  "PRIMEBANK": "PRIME COOPERATIVE BANK LIMITED",
  "THANEBHARATBANK": "THE THANE BHARAT SAHAKARI BANK LIMITED",
  "APMAHESHBANK": "THE A.P. MAHESH COOPERATIVE URBAN BANK LIMITED",
  "MUFG": "BANK OF TOKYO MITSUBISHI LIMITED",
  "GSCBANK": "THE GUJARAT STATE COOPERATIVE BANK LIMITED",
  "KVGBANK": "KARNATAKA VIKAS GRAMEENA BANK",
  "MUNICIPALBANK": "THE MUNICIPAL COOPERATIVE BANK LIMITED",
  "NNSBANK": "NAGPUR NAGARIK SAHAKARI BANK LIMITED",
  "KCCB": "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED",
  "RSCB": "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED",
  "SUDICOBANK": "THE SURAT DISTRICT COOPERATIVE BANK LIMITED",
  "VISHWESHWARBANK": "THE VISHWESHWAR SAHAKARI BANK LIMITED",
  "WOORIBANK": "WOORI BANK",
  "SUTEXBANK": "SUTEX COOPERATIVE BANK LIMITED",
  "BARCLAYS": "BARCLAYS BANK",
  "SHGB": "GURGAON GRAMIN BANK",
  "COMMBANK": "COMMONWEALTH BANK OF AUSTRALIA",
  "PRATHAMABANK": "PRATHAMA BANK",
  "KERALAGBANK": "KERALA GRAMIN BANK",
  "VARACHHABANK": "THE VARACHHA COOPERATIVE BANK LIMITED",
  "SBERBANK": "SBER BANK",
  "TGMCBANK": "TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED",
  "VASAIVIKASBANK": "VASAI VIKAS SAHAKARI BANK LTD",
  "BCCB": "BASSEIN CATHOLIC COOPERATIVE BANK LIMITED",
  "WESTPAC": "WESTPAC BANKING CORPORATION",
  "APGB": "ANDHRA PRAGATHI GRAMEENA BANK",
  "SMBC": "SUMITOMO MITSUI BANKING CORPORATION",
  "SEVAVIKASBANK": "THE SEVA VIKAS COOPERATIVE BANK LIMITED",
  "THANEDISTRICTBANK": "THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
  "JPMORGAN": "JP MORGAN BANK",
  "GDCCBANK": "THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
  "AKOLADCC": "THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK",
  "KURMANCHALBANK": "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED",
  "JPCBANK": "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED",
  "BNPPARIBAS": "PARIBAS",
  "NAB": "NATIONAL AUSTRALIA BANK LIMITED",
  "SDCBANK": "SAHEBRAO DESHMUKH COOPERATIVE BANK LIMITED",
  "MAYBANK": "PT BANK MAYBANK INDONESIA TBK",
  "SJSBBANK": "SOLAPUR JANATA SAHAKARI BANK LIMITED",
  "ICBC": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED",
  "UOB": "UNITED OVERSEAS BANK LIMITED",
  "ZSBL": "ZILA SAHAKRI BANK LIMITED GHAZIABAD",
  "JANASEVABANK": "JANASEVA SAHAKARI BANK LIMITED",
  "RAJGURUNAGARBANK": "RAJGURUNAGAR SAHAKARI BANK LIMITED",
  "CANARABANK": "CANARA BANK",
  "NUCB": "NAGAR URBAN CO OPERATIVE BANK",
  "AKOLAJANATABANK": "AKOLA JANATA COMMERCIAL COOPERATIVE BANK",
  "BMB": "BHARATIYA MAHILA BANK LIMITED",
  "HSBC": "HSBC BANK",
  "KANGRABANK": "THE KANGRA COOPERATIVE BANK LIMITED",
  "ZOROASTRIANBANK": "THE ZOROASTRIAN COOPERATIVE BANK LIMITED",
  "SHIKSHAKBANK": "SHIKSHAK SAHAKARI BANK LIMITED",
  "HASTIBANK": "THE HASTI COOP BANK LTD",
  "CSB": "CATHOLIC SYRIAN BANK LIMITED",
  "PRAGATHIKRISHNABANK": "PRAGATHI KRISHNA GRAMIN BANK",
  "DICGC": "DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION",
  "DBS": "DEVELOPMENT BANK OF SINGAPORE",
  "DOHABANK": "DOHA BANK QSC",
  "EXIMBANK": "EXPORT IMPORT BANK OF INDIA",
  "JKSBL": "JANAKALYAN SAHAKARI BANK LIMITED",
  "TJSBBANK": "TJSB SAHAKARI BANK LTD",
  "COSMOSBANK": "THE COSMOS CO OPERATIVE BANK LIMITED",
  "SURATNATIONALBANK": "SURAT NATIONAL COOPERATIVE BANK LIMITED",
  "CENTRALBANK": "CENTRAL BANK OF INDIA",
  "IDFC": "IDFC BANK LIMITED",
  "IBK": "INDUSTRIAL BANK OF KOREA",
  "SBM": "STATE BANK OF MYSORE",
  "NBAD": "NATIONAL BANK OF ABU DHABI PJSC",
  "KEBHANA": "KEB HANA BANK",
  "PANDHARPURBANK": "THE PANDHARPUR URBAN CO OP. BANK LTD. PANDHARPUR",
  "SAMARTHBANK": "SAMARTH SAHAKARI BANK LTD",
  "SHIVALIKBANK": "SHIVALIK MERCANTILE CO OPERATIVE BANK LTD",
  "HPSCB": "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD",
  "DEOGIRIBANK": "DEOGIRI NAGARI SAHAKARI BANK LTD. AURANGABAD",
  "CHINATRUST": "CHINATRUST COMMERCIAL BANK LIMITED",
  "MAHAGRAMIN": "MAHARASHTRA GRAMIN BANK",
  "EQUITAS": "EQUITAS SMALL FINANCE BANK LIMITED",
  "AIRTEL": "AIRTEL PAYMENTS BANK LIMITED",
  "CITIBANK": "CITI BANK",
  "CITIZENCREDITBANK": "CITIZEN CREDIT COOPERATIVE BANK LIMITED",
  "CUB": "CITY UNION BANK LIMITED",
  "CORPBANK": "CORPORATION BANK",
  "CACIB": "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK CALYON BANK",
  "DENABANK": "DENA BANK",
  "DB": "DEUSTCHE BANK",
  "DCB": "DCB BANK LIMITED",
  "DHANBANK": "DHANALAKSHMI BANK",
  "DNSBANK": "DOMBIVLI NAGARI SAHAKARI BANK LIMITED",
  "FIRSTRAND": "FIRSTRAND BANK LIMITED",
  "HDFC": "HDFC BANK",
  "ICICI": "ICICI BANK LIMITED",
  "IDBI": "IDBI BANK",
  "INDIANBANK": "INDIAN BANK",
  "IOB": "INDIAN OVERSEAS BANK",
  "INDUSIND": "INDUSIND BANK",
  "KAPOLBANK": "KAPOL COOPERATIVE BANK LIMITED",
  "KARNATAKABANK": "KARNATAKA BANK LIMITED",
  "KVB": "KARUR VYSYA BANK",
  "KOTAK": "KOTAK MAHINDRA BANK LIMITED",
  "MAHANAGARBANK": "MAHANAGAR COOPERATIVE BANK",
  "MSCBANK": "MAHARASHTRA STATE COOPERATIVE BANK",
  "MASHREQBANK": "MASHREQBANK PSC",
  "MIZUHOBANK": "MIZUHO BANK LTD",
  "NEWINDIABANK": "NEW INDIA COOPERATIVE BANK LIMITED",
  "NKGSB": "NKGSB COOPERATIVE BANK LIMITED",
  "NUTANBANK": "NUTAN NAGARIK SAHAKARI BANK LIMITED",
  "OBC": "ORIENTAL BANK OF COMMERCE",
  "GPPARSIKBANK": "PARSIK BANK",
  "PMC": "PUNJAB AND MAHARSHTRA COOPERATIVE BANK",
  "PSB": "PUNJAB AND SIND BANK",
  "PNB": "PUNJAB NATIONAL BANK",
  "RNSB": "RAJKOT NAGRIK SAHAKARI BANK LIMITED",
  "RBI": "RESERVE BANK OF INDIA",
  "SHINHAN": "SHINHAN BANK",
  "SOCIETEGENERALE": "SOCIETE GENERALE",
  "SOUTHINDIANBANK": "SOUTH INDIAN BANK",
  "SC": "STANDARD CHARTERED BANK",
  "SBBJ": "STATE BANK OF BIKANER AND JAIPUR",
  "SBHYD": "STATE BANK OF HYDERABAD",
  "SBI": "STATE BANK OF INDIA",
  "SBMGROUP": "STATE BANK OF MAURITIUS LIMITED",
  "SBP": "STATE BANK OF PATIALA",
  "SBT": "STATE BANK OF TRAVANCORE",
  "SYNDICATEBANK": "SYNDICATE BANK",
  "TMBL": "TAMILNAD MERCANTILE BANK LIMITED",
  "SCOTIABANK": "THE BANK OF NOVA SCOTIA",
  "AMCOBANK": "AHMEDABAD MERCANTILE COOPERATIVE BANK",
  "BHARATBANK": "BHARAT COOPERATIVE BANK MUMBAI LIMITED",
  "FEDERALBANK": "FEDERAL BANK",
  "GREATERBANK": "THE GREATER BOMBAY COOPERATIVE BANK LIMITED",
  "JKBANK": "JAMMU AND KASHMIR BANK LIMITED",
  "KALUPURBANK": "KALUPUR COMMERCIAL COOPERATIVE BANK",
  "KARNATAKAAPEX": "THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED",
  "KALYANJANATA": "KALYAN JANATA SAHAKARI BANK",
  "LVB": "LAXMI VILAS BANK",
  "MUCBANK": "THE MEHSANA URBAN COOPERATIVE BANK",
  "NAINITALBANK": "THE NAINITAL BANK LIMITED",
  "RBL": "RBL BANK LIMITED",
  "RBS": "THE ROYAL BANK OF SCOTLAND",
  "SARASWATBANK": "SARASWAT COOPERATIVE BANK LIMITED",
  "SVCBANK": "THE SHAMRAO VITHAL COOPERATIVE BANK",
  "SPCBL": "THE SURATH PEOPLES COOPERATIVE BANK LIMITED",
  "TNSCBANK": "THE TAMIL NADU STATE APEX COOPERATIVE BANK",
  "WBSCB": "THE WEST BENGAL STATE COOPERATIVE BANK",
  "UCOBANK": "UCO BANK",
  "UNIONBANK": "UNION BANK OF INDIA",
  "UNITEDBANK": "UNITED BANK OF INDIA",
  "VIJAYABANK": "VIJAYA BANK"
}

export const payoutStatusConstants = {
  MONTHLY: "Monthly",
  SPOT: "Spot",
  // ADVANCE: "Advance",
};

export const loginStatusConstants = {
  OPEN: "Open",
  APPLIED: "Applied",
  // WIP: "Work In Progress",
  APPROVED: "Approved",
  DISBURSED: "Disbursed",
  DECLINED: "Declined",
};

export const userRoleConstants = {
  CONNECTOR: "CONNECTOR",
  FIN_ADMIN: "FIN_ADMIN",
  CEO: "CEO",
  FIN_MANAGER: "FIN_MANAGER",
};

// application Status constants
export const loanStatusConstants = {
  OPEN: "OPEN",
  APPLIED: "APPLIED",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  DISBURSED: "DISBURSED",
  CANCELLED: "CANCELLED",
};

export const redeemedStatusConstants = {
  notProcessed: "Not Processed",
  reviewPending: "Review Pending",
  approvalPending: "Approval Pending",
  processed: "Processed",
  completed: "Completed"
};

export const productOrder = {
  'PL': 1,
  'AL': 2,
  'HL': 3,
  'BL': 4,
  'GL': 5,
  'IN': 6,
  'CC': 7,
  'EL': 8,

  'PERS': 1,
  'AUTO': 2,
  'HOME': 3,
  'BUSI': 4,
  'GOLD': 5,
  'INSR': 6,
  'CRED': 7,
  'EDUL': 8,
};

export const monthNumberToLabelMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}
