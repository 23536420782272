import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { Link, useLocation } from "react-router-dom";
const active = {
  background: "white",
  borderRadius: "5px",
  borderRight: 6,
  borderColor: "#40c4ff",
  '&:hover': {
    background: 'white',
  },
};
const nonActive = { borderRadius: "5px", color: "white" };

export default function AdminCEOList({handleDrawerToggle}) {
  let location = useLocation();
  const { pathname } = location;
  return (
    <div>
      <List>
        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="home" />
          )}
        >
          <ListItemButton
            sx={
              pathname === "/finmanager/home" || pathname === "/finmanager/"
                ? active
                : nonActive
            }
          >
            <ListItemIcon
              sx={
                pathname === "/finmanager/home" || pathname === "/finmanager/"
                  ? {}
                  : { color: "white" }
              }
              onClick={handleDrawerToggle}
            >
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="leads" />
          )}
        >
          <ListItemButton
            sx={pathname === "/finmanager/leads" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/finmanager/leads" ? {} : { color: "white" }}
            >
              <PersonOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Leads" />
          </ListItemButton>
        </ListItem>
        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="completed-payments" />
          )}
        >
          <ListItemButton
            sx={pathname === "/finmanager/completed-payments" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/finmanager/completed-payments" ? {} : { color: "white" }}
            >
              <PaymentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Completed Payments" />
          </ListItemButton>
        </ListItem>
        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="payment-approval" />
          )}
        >
          <ListItemButton
            sx={pathname === "/finmanager/payment-approval" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/finmanager/payment-approval" ? {} : { color: "white" }}
            >
              <AccountBalanceWalletOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Payment Approval" />
          </ListItemButton>
        </ListItem>


        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="admin-loan-enquiry" />
          )}
        >
          <ListItemButton
            sx={pathname === "/finmanager/admin-loan-enquiry" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/finmanager/admin-loan-enquiry"
                  ? {}
                  : { color: "white" }
              }
            >
              <FactCheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Enquiry" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="admin-add-connector" />
          )}
        >
          <ListItemButton
            sx={pathname === "/finmanager/admin-add-connector" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/finmanager/admin-add-connector"
                  ? {}
                  : { color: "white" }
              }
            >
              <PersonAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Add DigiRep" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem
        component={({ innerRef, ...props }) => (
          <Link {...props} to="admin-settings" />
        )}
      >
        <ListItemButton
          sx={pathname === "/finmanager/admin-settings" ? active : nonActive}
        >
          <ListItemIcon
            sx={
              pathname === "/finmanager/admin-settings" ? {} : { color: "white" }
            }
          >
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
      </ListItem> */}
        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="notification" />
          )}
        >
          <ListItemButton
            sx={pathname === "/finmanager/notification" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/finmanager/notification"
                  ? {}
                  : { color: "white" }
              }
            >
              <NotificationAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
}
