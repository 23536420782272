import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useContext } from "react";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import { bankNameConstants, productConstants } from "../../constants/appconstants";

const TopButtons = (props) => {

  const {
    freqValue,
    changeFreqValue,
    productType,
    changeProductType,
    bankName,
    changeBankName,
  } = useContext(AppContextProvider);

  const UpdateActiveButton = (freq) => {
    changeFreqValue(freq);
  };

  const handleChange = (event) => {
    changeProductType(event.target.value);
  };

  const handleBankName = (event) => {
    changeBankName(event.target.value);
  };

  return (
    <div>
      <div className="row tabs-section w-100">
        <div className="col-md-6 d-flex flex-nowrap ">
          <button
            className="buttons-tabs button-active"
            // className={
            //   freqValue === MTD ? "buttons-tabs button-active" : "buttons-tabs"
            // }
            // value={MTD}
          >
            Current Month
          </button>
        </div>
        <div className="col-md-6">
          <FormControl
            sx={{ 
              display: "flex",
              flexDirection: "row-reverse",
              m: 1,
              minWidth: 200,
              backgroundColor: "#f8f9fd",
              border: 0,
              outline: 0,
              // boxShadow: "10px 10px 6px -10px rgb(178 178 178 / 40%)",
            }}
            style={{ float: "right" }}
            className="dropdown-products"
          >
            <Select
              value={productType}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                minWidth: "150px",
                backgroundColor: "#fff",
              }}
              style={{ padding: "2px" }}

            >
              <MenuItem value={""}>All Products</MenuItem>
              {Object.keys(productConstants).map((i) => (
                <MenuItem value={i} key={i}>{productConstants[i]}</MenuItem>
              ))}
            </Select>

            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={bankName}
              onChange={handleBankName}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                minWidth: "150px",
                backgroundColor: "#fff",
                marginRight: "20px",
              }}
              style={{ padding: "2px" }}
            >
              <MenuItem value={""}>Bank Name</MenuItem>
              {Object.keys(bankNameConstants)
                .sort()
                .map((x) => (
                  <MenuItem
                    key={bankNameConstants[x]}
                    value={bankNameConstants[x]}
                  >
                    {x}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TopButtons);
