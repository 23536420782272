import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import GetAppIcon from '@mui/icons-material/GetApp';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import {
  ErrorSnackbar,
  SuccessSnackbar
} from "../../components/CustomSnackBar";
import PageLoader from "../../components/PageLoader";
import {
  bankCodeConstants,
  bankNameConstants, bankNameConstantsNationalised, stateConstants, stateNameToCodeMapping
} from "../../constants/appconstants";
import {
  putConnector,
  postConnector,
  resetLeads
} from "../../redux/reducers/LeadsDataService";
import BulkUpload from "../leads/bulkUpload";
import ConnectorTable from "./ConnectorTable";
import Alert from '@mui/material/Alert';
import { ApiUrl } from "../../helpers/getUrl";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
);
const phoneRegExp = new RegExp('[0-9]{10}');

const validationSchema = yup.object({
  connectorName: yup.string().required("DigiRep Name is Required"),
  connectorPhone: yup.string().required("DigiRep Phone is Required").matches(phoneRegExp,'Must be a valid phone'),
  connectorEmail: yup.string().email('Must be a valid email').required("DigiRep Email is Required"),
  // connectorManager: yup.string().required("Connector Manager is Required"),
  accountNumber: yup.string().required("Account Number is Required"),
  bankName: yup.string().required("Bank Name is Required"),
  ifsc: yup.string().required("IFSC Code is Required"),
  pan: yup.string().required("PAN Number is Required"),
  state: yup.string().required("State is Required"),
  //location: yup.string().required("Location is Required"),
});

const useStyles = makeStyles(() => ({
  tableCell: { textAlign: "center" },
  select: {
    width: "200px",
    margin: "5px",
  },
  formControl: {
    padding: "5px",
    width: "250px",
  },
}));

function Connector() {
  const classes = useStyles();
  const { connectorId } = useContext(AppContextProvider);
  const dispatch = useDispatch(); // used to dispatch an action

  const { isLoading, successMsg, errorMsg, invokeGetLeadsApi, errorDetails } = useSelector(
    ({ leadsDataService }) => leadsDataService
  );
  const [isEditMode, setEditMode] = useState(false);
  const [formDataInEditMode, setFormDataInEditMode] = useState(null);
  const resolver = useYupValidationResolver(validationSchema);

  let defaultValues = {
    connectorName: "",
    connectorPhone: '',
    connectorEmail: "",
    connectorManager: "",
    accountNumber: "",
    bankName: "",
    ifsc: "",
    pan: "",
    state: "",
    location: "",
    createdBy: ""
  }

  if (isEditMode && formDataInEditMode?._id) {
    defaultValues = {
      connectorName: formDataInEditMode?.connectorName,
      connectorPhone: formDataInEditMode?.connectorPhone,
      connectorEmail: formDataInEditMode?.connectorEmail,
      connectorManager: formDataInEditMode?.connectorManager,
      accountNumber: formDataInEditMode?.accountNumber,
      bankName: formDataInEditMode?.bankName,
      ifsc: formDataInEditMode?.ifsc,
      pan: formDataInEditMode?.pan,
      state: formDataInEditMode?.state,
      location: formDataInEditMode?.location,
      createdBy: formDataInEditMode?.createdBy 
    }
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver
  });

  window.register = register;

  const [openConnector, setOpenConnector] = useState(false);

  const resetData = () => {
    setOpenConnector(false);
    reset();
    setTimeout(() => {
      dispatch(resetLeads());
    }, 2000);
  };

  useEffect(() => {
    if (invokeGetLeadsApi) {
      setOpenConnector(false);
      reset();
      setTimeout(() => {
        dispatch(resetLeads());
      }, 2000);
    }
    return () => { };
  }, [invokeGetLeadsApi]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  const handleAddLead = () => {
    setEditMode(false);
    setOpenConnector(true);
    reset(defaultValues);
    reset();
  };

  const handleEditClick = (data) => {
    setFormDataInEditMode(data);
    setOpenConnector(true);
    setEditMode(true);
  };

  const handleClose = () => {
    setFormDataInEditMode({});
    setOpenConnector(false);
    setEditMode(false);
    reset();
  };

  const submitNewConnector = (data) => {
    let leadsData = {
      ...data
    };

    let email = (JSON.parse(localStorage?.user)||{userEmail:"login@demo.com"}).userEmail
    if (isEditMode) {
      delete leadsData.createdBy;
      delete leadsData.connectorId;
      delete leadsData.connectorEmail;
      leadsData.state=stateNameToCodeMapping[data.state];
      leadsData.bankName=bankNameConstants[data.bankName];
      leadsData.updatedBy = email;
      console.log(data.bankName)
      dispatch(putConnector(formDataInEditMode?.connectorId,{ ...leadsData }));
      setEditMode(false);
    } else {
      delete leadsData.updatedBy;
      leadsData.createdBy = email;
      leadsData.state=stateNameToCodeMapping[data.state];
      leadsData.bankName=bankNameConstants[data.bankName];
      dispatch(postConnector({ ...leadsData }));
    }
    // console.log(data, "data");
  };

  // useEffect(() => {
  //   dispatch(getLeadsData()); //Invoke Action
  // }, []);

  const defaultProps = {
    options: Object.entries(bankNameConstants).map(([key, value]) => ({
      key,
      value,
    })),
    getOptionLabel: (option) => option.key,
  };

  const addLeadElement = (
    <Dialog onBackdropClick="false" open={openConnector} onClose={handleClose}>
      <DialogTitle>{isEditMode ? 'Update' : 'Add'} DigiRep Module</DialogTitle>
      <form onSubmit={handleSubmit(submitNewConnector)}>
        <DialogContent sx={{ paddingTop: 0, marginTop: "-20px" }}>
          {errorDetails && errorDetails.map((item, index) =>
            <Alert severity="error">{item}</Alert>
          )}
          <div className="row form-fields mt-md-n5">
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("connectorName")}
                  label="DigiRep Name"
                  fullWidth
                  defaultValue={watch("connectorName")}
                  variant="standard"
                  error={errors?.connectorName && errors?.connectorName}
                  helperText={errors?.connectorName?.message}
                  required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  type="text"
                  margin="dense"
                  {...register("connectorPhone")}
                  label="DigiRep Mobile Number"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorPhone && errors?.connectorPhone}
                  helperText={errors?.connectorPhone?.message}
                  required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("connectorEmail")}
                  label="DigiRep Email"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorEmail && errors?.connectorEmail}
                  helperText={errors?.connectorEmail?.message}
                  disabled={isEditMode}
                  required
                />
              </FormControl>
            </div>

            {/* <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("connectorManager")}
                  label="Connector Manager"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorManager && errors?.connectorManager}
                  helperText={errors?.connectorManager?.message}
                />
              </FormControl>
            </div> */}

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("accountNumber")}
                  label="Account Number"
                  fullWidth
                  variant="standard"
                  error={errors?.accountNumber && errors?.accountNumber}
                  helperText={errors?.accountNumber?.message}
                  required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl} required>
                <Autocomplete
                  disablePortal
                  options={Object.keys(bankNameConstants)}
                  id="demo-simple-select-standard"

                  renderInput={(params) => <TextField
                    {...register("bankName")}
                    {...params} label="Bank Name"
                  />}
                  defaultValue={bankCodeConstants[watch('bankName')]}
                  
                />
                {errors?.bankName?.message && (
                  <FormHelperText error={errors?.bankName && errors?.bankName}>
                    {errors?.bankName?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("ifsc")}
                  label="IFSC Code"
                  fullWidth
                  variant="standard"
                  error={errors?.ifsc && errors?.ifsc}
                  helperText={errors?.ifsc?.message}
                  required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <Autocomplete
                  disablePortal
                  options={Object.values(stateConstants)}
                  id="demo-simple-select-standard"
                  renderInput={(params) => <TextField
                    {...register("state")}
                    {...params} label="State" />}
                  defaultValue={stateConstants[watch('state')]}
                  required
                />
                {errors?.state?.message && (
                  <FormHelperText
                    error={errors?.state && errors?.state}
                  >
                    {errors?.state?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("pan")}
                  label="PAN Number"
                  fullWidth
                  variant="standard"
                  error={errors?.pan && errors?.pan}
                  helperText={errors?.pan?.message}
                  required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("location")}
                  label="Connector Location"
                  fullWidth
                  variant="standard"
                  error={errors?.location && errors?.location}
                  helperText={errors?.location?.message}
                />
              </FormControl>
            </div>
          </div>
          {/* <FormControl variant="standard" className={classes.formControl}>

      </FormControl> */}
        </DialogContent>
        <DialogActions sx={{ padding: "0 20px 20px 0" }}>
          <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.leadButton}
            type="submit"
          // disabled={isLoading ? true : false}
          >
            {isEditMode ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <div className="sidenav-content">
      <div className="row my-2">
        <div className="col-md-6">
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="leads-heading"
          >
            DigiRep Details
          </Typography>
        </div>
        <div className="col-md-6">
          <Stack spacing={2} direction="row-reverse">
            <Button
              variant="contained"
              startIcon={<AddOutlinedIcon />}
              onClick={handleAddLead}
              className={classes.leadButton}
            >
              Add DigiRep
            </Button>
            <BulkUpload type="CONNECTOR_FINADMIN" />
            <a href={ApiUrl()+"template/admin/connectors"} target="_blank" >
              <Button
                variant="contained"
                startIcon={<GetAppIcon />}
                className={classes.leadButton}
              >
                Download Template
              </Button>
            </a>
          </Stack>
        </div>
      </div>

      <ConnectorTable handleEditClick={handleEditClick} resetData={resetData} />

      {addLeadElement}

      {isLoading && <PageLoader />}
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
    </div>
  );
}

export default Connector;
