import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect } from "react";
import { ApiUrl } from "../../helpers/getUrl";

function ApproveConnectorTable(params) {

    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [selectedRowData, setSelectedRows] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isloadData, setIsloadData] = React.useState(true);
    const localData = JSON.parse(localStorage.getItem('data'));

    useEffect(() => {
        axios.get(`${ApiUrl()}accounts/all`)
            .then(res => {
                let result = res?.data;
                result = result.filter(item => item.accountStatus !== "approved");
                setData(result);
                setIsloadData(false)
            }).catch(error => {
                console.error('There was an error!', error);
            });
    }, [isloadData]);


    let columns = [
        { headerName: "Account Holder", field: "accountHolderName", width: 190 },
        { headerName: "Account Number", field: "accountNumber", width: 190 },
        { headerName: "Bank Name", field: "bankName", width: 190 },
        { headerName: "IFSC Code", field: "ifsc", width: 200 },
        { headerName: "Gross Payable", field: "amount", width: 190 },
        { headerName: "Status", field: "accountStatus", width: 150 },
        { headerName: "PAN Number", field: "pan", width: 190 },
        {
            field: "actions",
            type: "actions",
            headerName: "Download",
            width: 100,
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<FileDownloadOutlinedIcon />}
                        disabled={!row?.attachmentId}
                        label="Edit"
                        onClick={() => {
                            handleDownloadClick(row);
                        }}
                        color="inherit"
                    />
                ];
            },
        }
    ];

    const handleDownloadClick = (row) => {
        console.log(row);
        const attachmentId = row.attachmentId;
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = row.fileName;

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(ApiUrl() + `accounts/documents/${attachmentId}`, requestOptions)
            .then(async response => {
                const blob = await response.blob();
                const newBlob = new Blob([blob]);
                const blobUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', row.fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(blob);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const handleSubmitForApproval = () => {
        console.log(selectedIds)
        setIsLoading(true);
        let value = {
            accountStatus: "approved",
            updatedBy: localData?.finadminEmail
        }
        selectedRowData.map(
            (row) => {
                axios.put(`${ApiUrl()}accounts/${row?.accountId}`, value)
                    .then((res) => {
                        console.log(res)
                    }).catch(error => {
                        console.log(error)
                    })
            })
        setIsLoading(false);
        setIsloadData(true);
    }

    return (
        <div className="row">
            <div
                style={{ height: 530, maxWidth: "100%" }}
                className="col-md-12 leads-table"
            >
                <DataGrid
                    rows={data}
                    getRowId={(e) => e?._id}
                    columns={columns}
                    pagination
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    height
                    checkboxSelection={true}
                    rowHeight={40}
                    // isRowSelectable={false}
                    onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = data.filter((row) =>
                            selectedIDs.has(row?._id)
                        );
                        setSelectedRows(selectedRowData);
                        setSelectedIds([...selectedIDs]);
                    }}
                    selectionModel={selectedIds}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => {
                        setPage(0);
                        setPageSize(newPageSize);
                    }}
                    loading={isLoading}
                    disableSelectionOnClick
                />
                <div className="col-md-12 my-3 pb-3">
                    <Stack spacing={2} direction="row-reverse">
                        <Button
                            variant="contained"
                            onClick={handleSubmitForApproval}
                            disabled={selectedRowData?.length < 1 ? true : false}
                        >
                            Approve
                        </Button>

                    </Stack>
                </div>
            </div>
        </div>
    );
}

export default ApproveConnectorTable;