import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MuiAppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState, useContext, useEffect } from "react";
import { userRoleConstants } from "../../constants/appconstants";
import { ApiUrl } from "../../helpers/getUrl";
import AppContext from "../contextProvider/AppContextProvider/AppContext";
import UserDropDown from '../UserDropdown';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import moment from 'moment';
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const useStyles = makeStyles(() => ({
  drawerWidth: {
    width: '25rem',
    marginTop: '4.5rem'
  },
  unpreservestyles: {
    marginTop: "inherit",
    marginBottom: "inherit",
    'font-size': 'medium',
    'font-family': 'Roboto',
    color: 'var(--bs-blue)'
  },
  expandedDrawer: {
    width: '75rem',
    marginTop: '4.5rem'
  },
  drawerHeight: {
    height: 'calc(100vh - 4.5rem)'
  },
  notifyDesc: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  active: {
    backgroundColor: '#14b30a8c !important'
  },
  unread: {
    backgroundColor: '#0a66b36e'
  },
  read: {
    backgroundColor: '#d3d3d3'
  },
  card: {
    cursor: 'pointer'
  },
  openedNotification: {
    padding: '1rem',
    height: '100%',
    margin: '1rem'
  }
}));

const convertDateTime = dateTime => {
  let returnDate = '';
  const actualDate = moment(dateTime);
  const now = moment();
  const seconds = now.diff(actualDate, 'seconds');
  if (seconds < 60) { // Less than a minute
    returnDate = `${seconds} secs`;
  } else if (seconds < 3600) { // Less than a hour
    const minutes = now.diff(actualDate, 'minutes');
    returnDate = `${minutes} mins`;
  } else if (seconds < 86400) { // Less than a day
    const hours = now.diff(actualDate, 'hours');
    returnDate = `${hours} hours`;
  } else {
    const years = now.diff(actualDate, 'years');
    if (years < 1) {
      const days = now.diff(actualDate, 'days');
      returnDate = `${days} days`;
    } else {
      returnDate = `${years} years`;
    }
  }
  return returnDate;
};

function Header(props) {
  const classes = useStyles(props);
  const { open } = useContext(AppContext);
  const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER } = userRoleConstants;

  const userRole = JSON.parse(localStorage.getItem('userRole'));
  const connector = JSON.parse(localStorage.getItem('data'));

  const [openDrawer, setOpenDrawer] = useState(false);
  const [expand, setExpand] = useState(false);
  const [openedNotification, setOpenedNotification] = useState(null);
  const [notifications, setNotifications] = useState(connector?.notifications || []);

  const noOfUnread = notifications.filter(notification => notification.isRead === false).length;
  const sortedNotifications = notifications.sort((a, b) => {
    const aNotifyOn = moment(a.notifyOn);
    const bNotifyOn = moment(b.notifyOn);
    return bNotifyOn.diff(aNotifyOn);
  });

  const toggleDrawer = () => {
    if (openDrawer) {
      setOpenDrawer(false);
      setExpand(false);
      setOpenedNotification(null);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleCardClick = notificationId => event => {
    console.log("notificationId|handleCardClick", notificationId)
    if (notificationId) {
      const notification = notifications.find(notification => notification.notificationId === notificationId);
      setExpand(true);
      setOpenedNotification(notification);
      const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
      };
      const connectorId = connector.connectorId;
      if (connectorId) {
        fetch(ApiUrl() + `connector/notification/${connectorId}/${notificationId}`, requestOptions)
          .then(response => {
            setNotifications(notifications.map(notification => {
              if (notification.notificationId === notificationId) {
                return {
                  ...notification,
                  isRead: true
                };
              }
              return notification;
            }));
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
      }
    }
  };

  return (
    <div>
      <AppBar position="absolute" open={open}
        sx={{
          boxShadow: 'none'
        }}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
            backgroundColor: { sm: 'white' },
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {true &&
            <>
              <Tooltip title="Notifications" arrow>
                <IconButton onClick={toggleDrawer}>
                  <Badge badgeContent={noOfUnread} color="secondary">
                    <NotificationsNoneOutlinedIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </>
          }
          <UserDropDown />
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        id="notificationdrawer"
        open={Boolean(openDrawer)}
        anchor='right'
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        classes={{ paper: expand ? classes.expandedDrawer : classes.drawerWidth }}
      >
        <Grid container className={classes.drawerHeight} >
          {
            expand ? (
              <Grid item sm={8}>
                <Paper className={classes.openedNotification}>
                  <IconButton aria-label="close" size="small" style={{ float: 'right' }} onClick={() => { setExpand(false); setOpenedNotification(null) }}>
                    <CancelPresentationIcon />
                  </IconButton>
                  <Typography gutterBottom variant="h5" component="div">
                    {openedNotification.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <pre className={classes.unpreservestyles}>
                      {openedNotification.description}
                    </pre>
                  </Typography>
                  {
                    openedNotification && openedNotification.attachmentId ? (
                      <div>
                        <img style={{ width: '100%', height: 'auto' }} src={`${ApiUrl()}attachment/${openedNotification.attachmentId}`} alt="Notification" />
                      </div>
                    ) : null
                  }
                  <Typography variant="body2" color="text.secondary">
                    <pre className={classes.unpreservestyles}>
                      <b>Regards,<br />
                        Shiva & Venkat<br />
                        Starpowerz Digital Technology Pvt. Ltd</b>
                    </pre>
                  </Typography>
                </Paper>
              </Grid>
            ) : null
          }
          <Grid item sm={expand ? 4 : 12}>
            {sortedNotifications && sortedNotifications.map(notification => (

              <Card
                sx={{ margin: '0.5rem' }}
                key={notification.notificationId}
                className={
                  `
                  ${classes.card}
                  ${notification.isRead ? classes.read : classes.unread}
                  ${openedNotification && openedNotification.notificationId === notification.notificationId ? classes.active : null}
                  `
                }
                onClick={handleCardClick(notification.notificationId)}
              >
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {notification.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" className={classes.notifyDesc}>
                    {notification.description}
                  </Typography>
                  <Typography variant="caption" component="div" style={{ float: 'right' }}>
                    {convertDateTime(notification.notifyOn)}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </div>
  );
}

export default Header;
