import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import {
  loanStatusConstants,
  userRoleConstants
} from "../../constants/appconstants";
import { getLeadsData } from "../../redux/reducers/GetLeadsDataService";
import { putLeadsData } from "../../redux/reducers/LeadsDataService";
import useAppContext from "../../utils/useAppContext";

const { APPLIED, OPEN } = loanStatusConstants;

export default function PaymentApprovalTable({ handleEditClick }) {
  let auth = useAppContext();
  const dispatch = useDispatch();
  const { connectorId } = useContext(AppContextProvider);
  const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER } = userRoleConstants;

  const { leadsData, isLoading, successMsg, errorMsg } = useSelector(
    ({ getLeadsDataService }) => getLeadsDataService
  );

  const { invokeGetLeadsApi } = useSelector(
    ({ leadsDataService }) => leadsDataService
  );

  const { data = [], count = 0 } = leadsData;

  let groupedData = _.groupBy(data, "connectorId");

  let result = [];

  for (var key in groupedData) {
    let element = { ...groupedData[key][0] };
    element.leadNumber = groupedData[key].length;
    element.connectorPayoutAmount = groupedData[key].reduce((n, { connectorPayoutAmount }) => n + connectorPayoutAmount, 0);
    result.push(element);
  }

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    let reqData = {
      limit: 5000,
      orderBy: "createdOn",
      orderDirection: "DESC",
      page: 1,
      search: {
        connectorPayoutStatus: "approvalPending",
      },
    };
    dispatch(getLeadsData(null, reqData)); //Invoke Action

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorId]);

  useEffect(() => {
    let reqData = {
      limit: 5000,
      orderBy: "createdOn",
      orderDirection: "DESC",
      page: 1,
      search: { connectorPayoutStatus: "approvalPending" },
    };
    if (invokeGetLeadsApi) {
      dispatch(getLeadsData(null, reqData)); //Invoke Action
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invokeGetLeadsApi]);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]);

  const handleSubmitAll = () => {
    let status = {
      connectorPayoutStatus: "processed",
      updatedBy: "user@email.com",
    };
    Promise.all(
      selectedRows.map(
        (item) => {
          const elements = groupedData[item.connectorId];
          elements.map(element => new Promise((resolve, reject) => {
            dispatch(putLeadsData(element?.leadId, status));
            resolve();
          }))
        }
      )
    ).then((values) => {
      let reqData = {
        limit: 5000,
        orderBy: "createdOn",
        orderDirection: "DESC",
        page: page + 1,
        search: { connectorPayoutStatus: "approvalPending" },
      };
      dispatch(getLeadsData(null, reqData));
      setSelectedIds([]);
    });
  };

  const handleCancel = () => {
    setSelectedIds([]);
  };

  let columns = [
    { headerName: "DigiRep Name", field: "connectorName", flex: 1 },
    { headerName: "Mobile Number", field: "connectorPhone", flex: 1 },
    { headerName: "Email ID", field: "connectorEmail", flex: 1 },
    { headerName: "Manager Name", field: "leadManager", flex: 1 },
    { headerName: "Number of Leads", field: "leadNumber", flex: 1 },
    { headerName: "Payout Amount", field: "connectorPayoutAmount", flex: 1 },
    { headerName: "Payment Status", field: "connectorPayoutStatus", flex: 1 },
  ];

  let modalColumns = [
    { headerName: "LeadID", field: "leadId", width: 150 },
    { headerName: "Customer Name", field: "customerName", width: 150 },
    { headerName: "Bank Name", field: "loanBank", width: 150 },
    { headerName: "Product Type", field: "productType", width: 150 },
    { headerName: "LAN Number", field: "lanNumber", width: 150 },
    { headerName: "Loan Amount", field: "loanAmount", width: 150 },
    { headerName: "Disbursment Amount", field: "disbursementAmount", width: 150 },
    { headerName: "Connector Payout Percentage", field: "connectorPayoutPercentage", width: 150 },
    { headerName: "Gross Payout Amount", field: "grossPayoutAmount", width: 150 },
    { headerName: "Connector Payout Amount", field: "connectorPayoutAmount", width: 150 },
    { headerName: "Location", field: "customerLocation", width: 150 },
    { headerName: "Bank Payout Percentage", field: "bankPayoutPercentage", width: 150 },
    { headerName: "Bank Payout Amount", field: "bankPayoutAmount", width: 150 },
    { headerName: "Remarks", field: "remarks", width: 150 },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '95vw',
    bgcolor: "background.paper",
    border: "1px solid #ccc",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const [selectedConnector, setSelectedConnector] = React.useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="row">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              rows={selectedConnector}
              getRowId={(e) => e.leadId}
              columns={modalColumns}
              rowHeight={36}
            />
          </div>
        </Box>
      </Modal>
      <div
        style={{ height: 400, maxWidth: "100%" }}
        className="col-md-12 leads-table"
      >
        <DataGrid
          rows={result}
          getRowId={(e) => e._id}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          rowCount={result.length}
          height
          checkboxSelection={auth.user === CONNECTOR ? false : true}
          // isCellEditable={true}
          // onRowEditStart={()=>{}}
          rowHeight={40}
          isRowSelectable={false}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = data.filter((row) =>
              selectedIDs.has(row._id)
            );
            setSelectedRows(selectedRowData);
            setSelectedIds([...selectedIDs]);
          }}
          selectionModel={selectedIds}
          // paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => {
            setPage(0);
            setPageSize(newPageSize);
          }}
          loading={isLoading}
          disableSelectionOnClick
          onCellClick={(params, event, details) => {
            setSelectedConnector(groupedData[params.row.connectorId]);
            handleOpen();
          }}
        />
        {auth.user !== CONNECTOR ? (
          <div className="col-md-12 my-3 pb-3">
            <Stack spacing={2} direction="row-reverse">
              <Button
                variant="contained"
                onClick={handleSubmitAll}
                disabled={selectedRows.length < 1}
              // className={classes.leadButton}
              >
                Approve
              </Button>

              <Button
                sx={{ boxShadow: 2 }}
                variant="text"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </div>
        ) : null}
      </div>
    </div>
  );
}
