import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from "@mui/material/Box";
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { userRoleConstants } from "../../constants/appconstants";
import useAppContext from "../../utils/useAppContext";
import AppContext from "../contextProvider/AppContextProvider/AppContext";
import AdminCEOList from "./AdminCEOList";
import AdminList from "./AdminList";
import CEOList from './CEOList';
import ConnectorList from "./ConnectorList";
import Logo from "./Logo.svg";
import Impetus from "./Impetus.jpeg";

const active = {
  background: "white",
  borderRadius: "5px",  
  borderRight: 6,
  borderColor: "#40c4ff",
  "&:hover": {
    background: "white",
  },
};
const nonActive = { borderRadius: "5px", color: "white" };

const drawerWidth = 240;



// const NewDrawer = styled(Drawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   "& .MuiDrawer-paper": {
//     position: "relative",
//     whiteSpace: "nowrap",
//     background: "#2e54da",
//     // background: "#061020",
//     width: drawerWidth,
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     boxSizing: "border-box",
//     ...(!open && {
//       overflowX: "hidden",
//       transition: theme.transitions.create("width", {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//       }),
//       width: theme.spacing(7),
//       [theme.breakpoints.up("sm")]: {
//         width: theme.spacing(9),
//       },
//     }),
//   },
// }));

function SideNav() {
  const { open, toggleDrawer } = useContext(AppContext);
  const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER } = userRoleConstants;
  let location = useLocation();
  const { pathname } = location;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    console.log(mobileOpen);
  };
 
  const { user } = useAppContext();
  // const user = 'FIN_MANAGER';
  
  let sideNavList = null;
  // useEffect(() => {
  if (user === CONNECTOR) {
    sideNavList = <ConnectorList handleDrawerToggle={handleDrawerToggle}/>;
  } else if (user === FIN_ADMIN) {
    sideNavList = <AdminList handleDrawerToggle={handleDrawerToggle} />;
  } else if (user === FIN_MANAGER) {
    sideNavList = <AdminCEOList handleDrawerToggle={handleDrawerToggle} />;
  } else if (user === CEO) {
    sideNavList = <CEOList handleDrawerToggle={handleDrawerToggle} />;
  }
  //console.log("mobileOpen",mobileOpen,pathname,user,sideNavList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {/* <Drawer variant="permanent" open={open}>
        <img src={Logo} alt="logo" style={{ padding: "20px" }} />
        {sideNavList}
      </Drawer> */}
      <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2,height:'70px', display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: false, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            
            "& .MuiDrawer-paper": {
              position: "relative",
              whiteSpace: "nowrap",
              background: "#2e54da",
              // background: "#061020",
              width: drawerWidth,
             
              boxSizing: "border-box",
              ...(!open && {
                overflowX: "hidden",
               
              }),
            },
          }}
        > 
          <img src={Impetus} alt="logo" style={{ padding: "20px" }} />
          {sideNavList}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: {xs:'none',sm:'column'},
            height:'100vh',

            "& .MuiDrawer-paper": {
              position: "relative",
              whiteSpace: "nowrap",
              background: "#2e54da",
              // background: "#061020",
              width: drawerWidth,
              
              boxSizing: "border-box",
              ...(!open && {
                overflowX: "hidden",
                
               
              }),
            },
          }}
          open
        >
          <img src={Impetus} alt="logo" style={{ padding: "20px" }} />
          {sideNavList}
        </Drawer>
      </Box>
  
    </Box>
    </>
  );
}

export default SideNav;
