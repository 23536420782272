import { useCallback, useState } from "react";
import axios from "axios";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import * as yup from "yup";

import { ApiUrl } from "../../../helpers/getUrl";
import { useForm } from "react-hook-form";

const data = [
  "name",
  "panStatus",
  "number",
  "aadhaarSeedingStatus",
  "typeOfHolder",
];

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const panRegex = new RegExp(/[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}/);

const validationSchema = yup.object({
  pan: yup.string().required("PAN Number is Required").max(10, 'PAN must be at most 10 characters').matches(panRegex, 'Must be a valid PAN number'),
});

export default function PanKYC({ OpenPanVerification, togglePanVerification, updateBalance }) {
  const { connectorId, finadminId, finMngrId, ceoId } =
    JSON.parse(localStorage.getItem("data")) || {};

  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);

  let defaultValues = { pan: '' };
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver
  });

  const verifyPan = (x) => {
    setLoading(true);
    const data = {
      number: x.pan,
      amount: 100,
    };

    if (connectorId) data['connectorId'] = connectorId;
    if (finadminId) data['finadminId'] = finadminId;
    if (finMngrId) data['finMngrId'] = finMngrId;
    if (ceoId) data['ceoId'] = ceoId;

    axios
      .post(`${ApiUrl()}kyc/pan`, data)
      .then((result) => {
        setUserDetail(result?.data?.pan);
        setLoading(false);
        updateBalance();
      })
      .catch((error) => {
        if (error.response.data.status === 404) {
          setUserDetail(error.response.data.issues[0].error);
          setLoading(false);
        }
      });
  };

  const banner = (
    <>
      {userDetail?.number &&
        data.map((title, index) => (
          <>
            <Grid
              container
              style={{ padding: "0.5rem", backgroundColor: "#E0FFFF" }}
            >
              <Grid item xs style={{ textTransform: "capitalize" }}>
                <strong>{title} </strong>
              </Grid>
              <Grid item xs>
                <Typography color="text.secondary">
                  {userDetail[title]}
                </Typography>
              </Grid>
            </Grid>
          </>
        ))}
      {userDetail?.message && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{userDetail?.message}</Alert>
        </Stack>
      )}
    </>
  );

  return (
    <Dialog
      open={OpenPanVerification}
      onClose={togglePanVerification}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Pan Verification
        <IconButton
          aria-label="close"
          onClick={togglePanVerification}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(verifyPan)}>
          <Grid item xs={12} style={{ padding: "0.5rem" }}>
            <TextField
              id="pan"
              label="PAN Number"
              variant="standard"
              fullWidth
              required
              {...register("pan")}
              defaultValue={watch("pan")}
              error={errors?.pan && errors?.pan}
              helperText={errors?.pan?.message}
              name="pan"
              autoFocus
            />
          </Grid>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            style={{ height: "3rem" }}
          >
            {loading ? <CircularProgress /> : "Verify"}
          </Button>
          <br />
          {banner}
        </form>
      </DialogContent>
    </Dialog>
  );
}
