import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useSelector } from "react-redux";
import { productConstants } from "../../constants/appconstants";
import Title from "./Title";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';

const useStyles = makeStyles((theme) => ({

  root: {
    // padding:'8px',
    [theme.breakpoints.only('xs')]: {
      padding: '8px',
    },
  }

}));

export default function Orders1(props) {

  const { productProjectionsData, isLoading, successMsg, errorMsg } = useSelector(({ productProjectionService }) => productProjectionService);
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>{props?.title}</Title>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 300 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {props?.data.map((x) => (
              <TableCell key={x} className={classes.root}>
                <b>{x}</b>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {productProjectionsData?.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{productConstants[row.productType]}</TableCell>
              <TableCell>{row.totalDisbursedAmount.cur()}</TableCell>
              <TableCell>{row.totalConnectorPayout.cur()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      </Paper>
    </React.Fragment>
  );
}
