import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ApiUrl } from '../../../helpers/getUrl';
import useAppContext from "../../../utils/useAppContext";

export default function TopTenBanks() {
    const { freqValue, productType, connectorId } = useAppContext();

    const [result, setResult] = useState([]);

    useEffect(() => {
        getData();
    }, [productType, freqValue]);

    const getData = () => {
        axios.get(`${ApiUrl()}projection/bank/ranking?product=${productType}`)
            .then(res => {
                let data = res.data;
                data.forEach(element => {
                    element.previous = element.previous || 0;
                    element.current = element.current || 0;
                    let total = element.previous;
                    let diff = element.current - element.previous;
                    element.percentage =  +(total?((diff/total)*100).toPrecision(4):'100');
                });
                data = data.sort((a, b) => b.percentage - a.percentage).slice(0, 10);
                setResult(data);
            }).catch(error => {
                console.error('There was an error!', error);
            });
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Last Month Leads</TableCell>
                        <TableCell>This Month Leads</TableCell>
                        <TableCell>% Change</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {result.map((row) => (
                        <TableRow
                            key={row.connectorId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.loanBank}
                            </TableCell>
                            <TableCell>{row.previous}</TableCell>
                            <TableCell>{row.current}</TableCell>
                            <TableCell style={{ color: isNaN(row.percentage) ? 'gray' : (row.percentage < 0) ? 'red' : 'green' }}>
                                {row.percentage} &nbsp;
                                {isNaN(row.percentage) ? null : (row.percentage < 0) ? <ArrowDownward /> : <ArrowUpward />}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
