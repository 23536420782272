import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid, getGridStringOperators, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import { bankCodeConstants, loanStatusConstants, payoutStatusConstants, productConstants, redeemedStatusConstants, stateConstants, userRoleConstants } from "../../constants/appconstants";
import { getLeadsData } from "../../redux/reducers/GetLeadsDataService";
import { putLeadsData } from "../../redux/reducers/LeadsDataService";
import useAppContext from "../../utils/useAppContext";

const { APPLIED, OPEN, DISBURSED } = loanStatusConstants;
const { completed } = redeemedStatusConstants;
const { MONTHLY, SPOT } = payoutStatusConstants;

export default function AdminLeadsTable({ handleEditClick }) {
  let auth = useAppContext();
  const dispatch = useDispatch();
  //const { connectorId } = useContext(AppContextProvider);
  const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER } = userRoleConstants;
  const connectorData = JSON.parse(localStorage.getItem('data'));
  const { connectorId = null } = connectorData;

  const { leadsData, isLoading, successMsg, errorMsg } = useSelector(
    ({ getLeadsDataService }) => getLeadsDataService
  );

  const { invokeGetLeadsApi } = useSelector(
    ({ leadsDataService }) => leadsDataService
  );

  const { data = [], count = 0 } = leadsData;

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const search = useLocation().search;

  const [filterModel, setFilterModel] = React.useState({ items: [{ columnField: 'productType', operatorValue: 'contains', value: '' }] });
  let [sortModel, setSortModel] = React.useState([
    { field: 'createdOn', sort: 'asc' },
  ]);

  const [reqData, setreqData] = useState({
    limit: pageSize,
    orderBy: "createdOn",
    orderDirection: "DESC",
    range: "MTD",
    page: page + 1,
    search: {},
  });

  const getComputedReqData = () => {
    const type = new URLSearchParams(search).get("type");
    const productType = new URLSearchParams(search).get("productType");
    if (type) {
      if (["DISBURSEMENTLISTING", "BANKPAYOUT"].includes(type.toUpperCase())) {
        reqData.search = { loanStatus: DISBURSED }
      } else if (type.toUpperCase() === "CONNECTORPAYOUT") {
        reqData.search = { connectorPayoutStatus: completed.toLowerCase() }
      } else if (type.toUpperCase() === "SPOTPAYOUT") {
        reqData.search = {
          connectorPayoutOption: SPOT.toUpperCase(),
          connectorPayoutStatus: ["approvalPending", "reviewPending", "processed"]
        }
      } else if (type.toUpperCase() === "MONTHLYPAYOUT") {
        reqData.search = {
          connectorPayoutOption: MONTHLY.toUpperCase(),
          connectorPayoutStatus: ["approvalPending", "reviewPending", "processed"]
        }
      } else if (type.toUpperCase() === "UNMAPPEDPAYOUT") {
        reqData.search = { loanStatus: DISBURSED, connectorName: "", unmapped: true }
      } else if (type.toUpperCase() === "PENDINGPAYOUT") {
        reqData.search = {
          connectorPayoutStatus: ["approvalPending", "reviewPending", "processed"]
        }
      }
      reqData.range = "MTD"
    }
    reqData.page = page + 1;
    reqData.limit = pageSize;
    return reqData;
  }

  const setReqData = () => {
    let ret = getComputedReqData();
    let filterSearch = filterModel.items.reduce((o, n) => { n.value && (o[n.columnField] = n.value); return o; }, {});
    //console.log("onFilterChange filterSearch", filterSearch, reqData.search);
    const type = new URLSearchParams(search).get("type");
    const productType = new URLSearchParams(search).get("productType");
    const bankName = new URLSearchParams(search).get("bank");
    const range = new URLSearchParams(search).get("range");

    if (type?.toUpperCase() === "UNMAPPEDPAYOUT") {
      filterSearch = {
        ...filterSearch,
        unmapped: true,
      }
    } else if (["SPOTPAYOUT", "MONTHLYPAYOUT"].includes(type?.toUpperCase())) {
      filterSearch = {
        ...filterSearch,
        connectorPayoutOption: type?.toUpperCase() != "SPOTPAYOUT" ? MONTHLY.toUpperCase() : SPOT.toUpperCase(),
      }
    }
    if (["UNMAPPEDPAYOUT", "DISBURSEMENTLISTING", "BANKPAYOUT"].includes(type?.toUpperCase())) {
      filterSearch = {
        ...filterSearch,
        loanStatus: DISBURSED,
      }
    }
    if (["SPOTPAYOUT", "PENDINGPAYOUT", "MONTHLYPAYOUT"].includes(type?.toUpperCase())) {
      filterSearch = {
        ...filterSearch,
        connectorPayoutStatus: ["approvalPending", "reviewPending", "processed"],
      }
    }
    if (productType) {
      filterSearch = {
        ...filterSearch,
        productType: productType
      }
    }
    if (bankName) {
      filterSearch = {
        ...filterSearch,
        loanBank: bankName
      }
    }
    if (range) {
      reqData.range = range;
    }
    if (["DISBURSED", "COMPLETED"].includes(type?.toUpperCase())) {
      filterSearch = {
        ...filterSearch,
        loanStatus: type.toUpperCase(),
      }
    }
    let payload = {
      ...ret,
      orderBy: sortModel[0].field,
      orderDirection: sortModel[0].sort.toUpperCase(),
      range: reqData.range,
      search: {
        ...filterSearch
      }
    };
    setreqData(payload);
    //setSelectedIds(JSON.parse(selectedIDs));
    return payload;
  }

  //let selectedIDs = ["61a62a7ee5faee5c4752388f"];
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]);
  let adminLeadsTableSelectedIDs = JSON.parse(localStorage.getItem(`adminLeadsTableSelectedIDs`) || "{}");
  let selectedIDs = adminLeadsTableSelectedIDs[page] || [];

  //console.log("selectedIds", selectedIds);

  useEffect(() => {
    let payload = setReqData();
    dispatch(getLeadsData(connectorId, payload, () => {
      setSelectedIds(selectedIDs);
    })); //Invoke Action
  }, [filterModel, sortModel, page, pageSize]);


  useEffect(() => {
    if (invokeGetLeadsApi) {
      dispatch(getLeadsData(connectorId, reqData, () => {
        setSelectedIds(selectedIDs);
      })); //Invoke Action
    }
    return () => { };
  }, [invokeGetLeadsApi]);





  const onPageChange = (newPage) => {
    setSelectedIds([]);
    setPage(newPage);
  };


  const handleSubmitAll = () => {
    const fetchData = () => {
      let reqData = {
        limit: pageSize,
        orderBy: "createdOn",
        orderDirection: "DESC",
        page: page + 1,
        search: {},
      };
      setSelectedIds([]);
      localStorage.setItem(`adminLeadsTableSelectedIDs`, "[]");
      adminLeadsTableSelectedIDs = [];
      //setPage(0);
      dispatch(getLeadsData(null, reqData));
    };
    //fetchData();
    //return;
    Promise.all(
      selectedRows.map(
        (lead) =>
          new Promise((resolve, reject) => {
            // httpPut(
            //   `${url}/${lead.leadId}`,
            //   { connectorPayoutStatus: "reviewPending" },
            //   null,
            //   resolve,
            //   reject
            // );
            let body = {
              ...lead,
              connectorPayoutStatus: "approvalPending",
            };
            dispatch(putLeadsData(lead?.leadId, body));
            resolve();
          })
      )
    ).then((values) => {
      fetchData();
    });

    // leadId
    // console.log("rows Selected", selectedRows);
  };

  const handleCancel = () => {
    setSelectedIds([]);
  };


  let columns = [
    {
      field: "actions", type: "actions", headerName: "Action", width: 90,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => { handleEditClick(row) }}
            color="inherit"
            disabled={row.connectorPayoutStatus == "completed" || (row.loanStatus != "OPEN" && connectorId)}
          />
        ]
      },
    },

    { headerName: "Lead Id", field: "leadId", width: 130, filterable: false },
    {
      headerName: "Product Type", field: "productType", width: 140,
      align: 'left', headerAlign: 'left',
      renderCell: ({ row }) => <>{productConstants[row.productType]}</>
    },
    {
      headerName: "Bank Name", field: "loanBank", width: 160,
      renderCell: (params) => <>{bankCodeConstants[params.value]}</>
    },
    { headerName: "Application Number", field: "applicationNumber", width: 220 },
    { headerName: "Application Date", field: "applicationDateFormat", width: 200, filterable: false },
    { headerName: "Login Status", field: "loginStatus", width: 220 },
    { headerName: "Loan Amount", field: "loanAmount", width: 180, filterable: false },
    { headerName: "LAN Number", field: "lanNumber", width: 200 },
    { headerName: "Disbursed Amount", field: "disbursementAmount", width: 240, filterable: false },
    { headerName: "Disbursed Date", field: "disbursementDateFormat", width: 150, filterable: false },
    { headerName: "Payout Type", field: "connectorPayoutOption", width: 160 },
    { headerName: "Customer Name", field: "customerName", width: 200 },
    { headerName: "Customer Mobile", field: "customerPhone", width: 190, filterable: false },
    {
      headerName: "Loan State", field: "loanState", width: 190,
      renderCell: (params) => <>{stateConstants[params.value]}</>
    },
    { headerName: "Location", field: "location", width: 150 },
    { headerName: "DigiRep Payout %", field: "connectorPayoutPercentage", width: 150, filterable: false },
    { headerName: "DigiRep Payout Amount", field: "connectorPayoutAmount", width: 180, filterable: false },
    { headerName: "DigiRep payout Date", field: "connectorPayoutDateFormat", width: 200, filterable: false },
    {
      headerName: "DigiRep Payment Status", field: "connectorPayoutStatus", width: 150,
      renderCell: (params) => <>{redeemedStatusConstants[params.value]}</>
    },
    { headerName: "Loan Status", field: "loanStatus", width: 220 },
  ];

  if (auth.user !== CONNECTOR) {
    columns.push(
      { headerName: "Bank Payout %", field: "bankPayoutPercentage", width: 160, filterable: false },
      { headerName: "Bank Payout Amount", field: "bankPayoutAmount", width: 160, filterable: false },
      { headerName: "DigiRep Name", field: "connectorName", width: 150 },
      { headerName: "DigiRep Phone", field: "connectorPhone", width: 150 },
      { headerName: "DigiRep Email", field: "connectorEmail", width: 150 },
    );
  }
  // console.log("auth.user === CONNECTOR", auth.user === CONNECTOR, columns);

  const columnsWithFilterOptions = React.useMemo(
    () =>
      columns.map((col) => {

        return {
          ...col,
          filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'contains',
          ),

        };
      }),
    [columns],
  );

  const onFilterChange = (filter) => {
    setFilterModel(filter);
  }

  const onSortChange = (sort) => {
    if (sort.length) {
      let selected = sortModel[0] ?? {};
      let newsort = sort[0] ?? {};
      if (selected.field != newsort.field || selected.sort != newsort.sort) {
        setSortModel([
          newsort
        ])
        setReqData();
      }
    }
  }

  return (
    <div className="row">
      <div
        style={{ height: 530, maxWidth: "100%" }}
        className="col-md-12 leads-table"
      >
        <DataGrid

          rows={data}
          getRowId={(e) => e._id}
          columns={columnsWithFilterOptions}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          rowCount={count}
          height
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={onFilterChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortChange}
          checkboxSelection={auth.user === CONNECTOR ? false : true}
          // isCellEditable={true}
          // onRowEditStart={()=>{}}
          rowHeight={40}
          isRowSelectable={(params) => params.row.connectorPayoutStatus === 'reviewPending'}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = data.filter((row) =>
              selectedIDs.has(row._id)
            );
            setSelectedRows(selectedRowData);
            setSelectedIds([...selectedIDs]);
            adminLeadsTableSelectedIDs[page] = [...selectedIDs];
            localStorage.setItem(`adminLeadsTableSelectedIDs`, JSON.stringify(adminLeadsTableSelectedIDs));
            // console.log("ids", [selectedIDs]);
            // console.log("onSelectionModelChange:", selectedRowData);
          }}
          selectionModel={selectedIds}
          paginationMode="server"
          onPageChange={(newPage) => onPageChange(newPage)}
          onPageSizeChange={(newPageSize) => {
            setPage(0);
            setPageSize(newPageSize);
          }}
          loading={isLoading}
          disableSelectionOnClick
        />
        {auth.user !== CONNECTOR ? (
          <div className="col-md-12 my-3 pb-3">
            <Stack spacing={2} direction="row-reverse">
              <Button
                variant="contained"
                onClick={handleSubmitAll}
                disabled={selectedRows.length < 1 ? true : false}
              // className={classes.leadButton}
              >
                Submit for Approval
              </Button>

              <Button
                sx={{ boxShadow: 2 }}
                variant="text"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </div>
        ) : null}
      </div>
    </div>
  );
}