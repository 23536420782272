import React, { useEffect, useState, useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  postLeadsData,
  putLeadsData,
  resetLeads,
} from "../../redux/reducers/LeadsDataService";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Autocomplete from '@mui/material/Autocomplete';

import { makeStyles } from "@mui/styles";
import {
  stateConstants,
  bankNameConstants,
  bankNameConstantsNationalised,
  bankCodeConstants,
  productConstants,
  payoutStatusConstants,
  stateNameToCodeMapping,
  userRoleConstants,
  loginStatusConstants
} from "../../constants/appconstants";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LeadsTable from "./AdminLeadsTable";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import PageLoader from "../../components/PageLoader";
import useAppContext from "../../utils/useAppContext";

import {
  ErrorSnackbar,
  SuccessSnackbar,
} from "../../components/CustomSnackBar";
import BulkUpload from "./bulkUploadConnector";
import moment from 'moment';

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors?.inner?.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const phoneRegExp = new RegExp('[1-9]{1}[0-9]{9}');

const validationSchema = yup.object({
  customerName: yup.string().required("Customer Name is required"),
  productType: yup.string().required("Product Type is required"),
  loginStatus: yup.string().required("Login Status is required"),
  customerPhone: yup
    .string().when('loginStatus', {
      is: (loginStatus) => { console.log(loginStatus); return loginStatus === 'DISBURSED'; },
      then: yup.string().matches(phoneRegExp, "Must be a valid phone")
    })
  ,
  loanBank: yup.string().required("Bank Name is required"),
  loanAmount: yup
    .number().positive()
    .min(1).typeError('Must be a valid amount')
    .required("Loan Amount is required"),
  leadManager: yup.string(),
  location: yup.string().required("Location is required"),
  loanState: yup.string().required("State is required"),
  applicationNumber: yup.string(),
  applicationDate: yup.string().when('applicationNumber', {
    is: (applicationNumber) => { return applicationNumber.length > 0; },
    then: yup.string().required("Application Date is required")
  }),
  connectorPayoutOption: yup.string().required("Payout Type is required"),
});

const useStyles = makeStyles(() => ({
  tableCell: { textAlign: "center" },
  select: {
    width: "200px",
    margin: "5px",
  },
  // textField: {
  //   marginLeft: '30px'
  // },
  formControl: {
    padding: "5px",
    width: "250px",
  },
}));

function Leads() {
  const today = moment(new Date()).format('YYYY-MM-DD');
  const classes = useStyles();
  // const { connectorId } = useContext(AppContextProvider);
  const [openLead, setOpenLead] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [formDataInEditMode, setFormDataInEditMode] = useState(null);
  const [applicationDate, setApplicationDate] = useState('');
  const [applicationNumber, setApplicationNumber] = useState(null);
  const [displayPhone, setDisplayPhone] = useState(false);
  const { connectorEmail, connectorId, connectorManager, connectorName, connectorPhone } = JSON.parse(localStorage.getItem('data'));
  const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER } = userRoleConstants;

  let auth = useAppContext();

  const dispatch = useDispatch(); // used to dispatch an action

  const { isLoading, successMsg, errorMsg, invokeGetLeadsApi } = useSelector(
    ({ leadsDataService }) => leadsDataService
  );
  const resolver = useYupValidationResolver(validationSchema);

  let defaultValues = {
    applicationDate: '',
    connectorPayoutOption: "MONTHLY",
    loanStatus: "OPEN",
    sourceType: "INTERNAL",
    disbursementAmount: 0,
    disbursementDate: "",
    productType: "",
    loginStatus: "OPEN",

    connectorPayoutPercentage: 0,
    connectorPayoutAmount: 0,
    connectorPayoutDate: "",

    bankPayoutPercentage: 0,
    bankPayoutAmount: 0,
    bankPayoutDate: "",

    createdBy: "mkhati@gmail.com",
    lanNumber: "",
    paymentStatus: "notProcessed",
    remarks: "",
  };

  // console.log(formDataInEditMode?.leadId, "oust");

  if (isEditMode && formDataInEditMode?.leadId) {
    defaultValues = {
      ...defaultValues,
      applicationDate: formDataInEditMode?.applicationDate || "",
      applicationNumber: formDataInEditMode?.applicationNumber || "",
      customerName: formDataInEditMode?.customerName || "",
      productType: formDataInEditMode?.productType || "",
      customerPhone: formDataInEditMode?.customerPhone || "",
      loanBank: formDataInEditMode?.loanBank || "",
      loanAmount: formDataInEditMode?.loanAmount || 0,
      leadManager: formDataInEditMode?.leadManager || "",
      loanState: formDataInEditMode?.loanState || "",
      location: formDataInEditMode?.location || "",
      loginStatus: formDataInEditMode?.loginStatus || "",
      connectorPayoutOption: formDataInEditMode?.connectorPayoutOption || "",
    };
  }

  useEffect(() => {
    reset(defaultValues);
  }, [isEditMode]);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    watch,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (openLead === false && isEditMode === true) {
      setFormDataInEditMode(null);
      setEditMode(false);
    }
  }, [openLead]);

  useEffect(() => {
    if (invokeGetLeadsApi) {
      setOpenLead(false);
      reset();
      setTimeout(() => {
        dispatch(resetLeads());
      }, 2000);
    }
    return () => { };
  }, [invokeGetLeadsApi]);

  const handleAddLead = () => {
    setOpenLead(true);
    setEditMode(false);
    setFormDataInEditMode(null);
    reset(defaultValues);
  };
  const handleEditClick = (data) => {
    setOpenLead(true);
    setEditMode(true);
    setFormDataInEditMode(data);
    setApplicationNumber(data?.applicationNumber);
    setApplicationDate(moment(data?.applicationDate).format('YYYY-MM-DD'));
  };

  const handleClose = () => {
    setOpenLead(false);
    setEditMode(false);
    setFormDataInEditMode(null);
    setApplicationDate('');
    setApplicationNumber(null);
    setDisplayPhone(false);
  };

  const handleApplicationDate = (e) => {
    setApplicationDate(e.target.value);
    setValue('applicationDate', e.target.value);
  }

  const handleApplicationNumber = (e) => {
    const value = e.target.value;
    setApplicationNumber(value);
    setValue('applicationNumber', value);
    if (!value) {
      setApplicationDate('');
      setValue('applicationDate', '');
    }
  }

  const onSubmit = (data) => {
    let leadsData = {
      ...data,
      loanBank: bankNameConstantsNationalised[data.loanBank],
      loanState: stateNameToCodeMapping[data.loanState],
      loanStatus: data.applicationNumber ? 'APPLIED' : 'OPEN',
      connectorEmail, connectorId, connectorName, connectorPhone,
      connectorPayoutStatus: 'notProcessed'
    };

    if (isEditMode) {
      const { applicationDate, applicationNumber, customerName, productType, customerPhone, loanBank, loanAmount,
        leadManager, loanState, loanStatus, location, connectorPayoutOption } = leadsData;
      const data = {
        applicationDate, applicationNumber, customerName, productType, customerPhone, connectorPayoutOption,
        loanBank, loanAmount, leadManager, loanState, location, loanStatus: applicationNumber ? 'APPLIED' : 'OPEN',
      };

      dispatch(putLeadsData(formDataInEditMode?.leadId, data));
    } else {
      dispatch(postLeadsData({ ...leadsData }));
    }
    setApplicationNumber(null);
    setApplicationDate('');
  };

  const handleLoginStatus = (e) => {
    const value = e.target.value;
    value !== 'DISBURSED' ? setDisplayPhone(false) : setDisplayPhone(true);
  }
  // const onPickDate = (id) => {
  //   document.getElementById("ad").type = "date";
  // }
  const addLeadElement = (
    <Dialog onBackdropClick="false" open={openLead} onClose={handleClose}>
      <DialogTitle>Lead Entry Module - DigiRep</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ paddingTop: 0, marginTop: "-20px" }}>
          <div className="row form-fields mt-md-n5">
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("customerName")}
                  label="Customer Name"
                  fullWidth
                  variant="standard"
                  error={errors?.customerName && errors?.customerName}
                  helperText={errors?.customerName?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel id={"label-Product Type"}>
                  {"Product Type"}
                </InputLabel>
                <Select
                  className={classes.textField}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "20px" }}
                  {...register("productType")}
                  label="Product Type"
                  defaultValue={watch("productType")}
                >
                  <MenuItem disabled value="">
                    Select Product
                  </MenuItem>
                  {Object.keys(productConstants)?.map((x) => (
                    <MenuItem value={x}>{productConstants[x]}</MenuItem>
                  ))}
                </Select>
                {errors?.productType?.message && (
                  <FormHelperText
                    error={errors?.productType && errors?.productType}
                  >
                    {errors?.productType?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <Autocomplete
                  options={Object.keys(bankNameConstantsNationalised)}
                  id="demo-simple-select-standard"
                  renderInput={(params) => <TextField
                    {...register("loanBank")}
                    {...params} label="Bank Name" />}
                  defaultValue={bankCodeConstants[watch("loanBank")]}
                />
                {errors?.loanBank?.message && (
                  <FormHelperText error={errors?.loanBank && errors?.loanBank}>
                    {errors?.loanBank?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  type="number"
                  {...register("loanAmount")}
                  label="Loan Amount"
                  fullWidth
                  InputProps={{ inputProps: { min: 1 } }}
                  variant="standard"
                  error={errors?.loanAmount && errors?.loanAmount}
                  helperText={errors?.loanAmount?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              {/* <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("leadManager")}
                  label="Manager"
                  fullWidth
                  variant="standard"
                  error={errors?.leadManager && errors?.leadManager}
                  helperText={errors?.leadManager?.message}
                />
              </FormControl> */}
              <FormControl variant="standard" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "20px" }}
                  {...register("connectorPayoutOption")}
                  label="Pay Out Type"
                  fullWidth
                  defaultValue={watch("connectorPayoutOption")}
                >
                  {Object.keys(payoutStatusConstants).map((x) => (
                    <MenuItem value={x}>{payoutStatusConstants[x]}</MenuItem>
                  ))}
                </Select>
                <InputLabel>Pay Out Type</InputLabel>
                {errors?.connectorPayoutOption?.message && (
                  <FormHelperText
                    error={errors?.connectorPayoutOption && errors?.connectorPayoutOption}
                  >
                    {errors?.connectorPayoutOption?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <Autocomplete
                  disablePortal
                  options={Object.values(stateConstants)}
                  id="demo-simple-select-standard"
                  renderInput={(params) => <TextField
                    {...register("loanState")}
                    {...params} label="State" />}
                  defaultValue={stateConstants[watch("loanState")]}
                />
                {errors?.loanState?.message && (
                  <FormHelperText
                    error={errors?.loanState && errors?.loanState}
                  >
                    {errors?.loanState?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("location")}
                  label="Location"
                  fullWidth
                  variant="standard"
                  error={errors?.location && errors?.location}
                  helperText={errors?.location?.message}
                />
              </FormControl>
            </div>
            {displayPhone && <div className="col-md-6">
            </div>}
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <Select
                  labelId="loginStatus"
                  id="loginStatus"
                  sx={{ marginTop: "20px" }}
                  {...register("loginStatus")}
                  label="Login Status"
                  fullWidth
                  defaultValue={watch("loginStatus")}
                  onChange={handleLoginStatus}
                >
                  {Object.keys(loginStatusConstants).map((x) => (
                    <MenuItem value={x}>{loginStatusConstants[x]}</MenuItem>
                  ))}
                </Select>
                <InputLabel>Login Status</InputLabel>
                {errors?.loginStatus?.message && (
                  <FormHelperText
                    error={errors?.loginStatus && errors?.loginStatus}
                  >
                    {errors?.loginStatus?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            {displayPhone && <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  type="text"
                  margin="dense"
                  {...register("customerPhone")}
                  label="Customer Mobile Number"
                  fullWidth
                  variant="standard"
                  error={errors?.customerPhone && errors?.customerPhone}
                  helperText={errors?.customerPhone?.message}
                />
              </FormControl>
            </div>}

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("applicationNumber")}
                  label="Application Number"
                  variant="standard"
                  onChange={handleApplicationNumber}
                  error={errors?.applicationNumber && errors?.applicationNumber}
                  helperText={errors?.applicationNumber?.message}
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={applicationNumber?.length > 0 ? 'w-100' : 'd-none'}>
                {/* <label for="applicationDate">Application Date</label> */}
                <TextField
                  id="ad"
                  className="date-picker"
                  type="date"
                  label="Application Date"
                  defaultValue={applicationDate}
                  inputProps={{ max: today }}
                  InputLabelProps={{ shrink: true }}
                  // onFocus={onPickDate}
                  onChange={handleApplicationDate}
                  error={errors?.applicationDate}
                  helperText={errors?.applicationDate?.message}
                />
              </FormControl>
            </div>

            {/* <div className="col-md-6">

            </div> */}
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "0 30px 20px 0" }}>
          <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.leadButton}
            type="submit"
          // disabled={isLoading ? "true" : "false"}
          >
            {isEditMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  console.log("auth.user", auth.user, auth.user === CONNECTOR);

  return (
    <div className="sidenav-content">
      <div className="row my-2">
        <div className="col-md-6">
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="leads-heading"
          >
            Leads Data
          </Typography>
        </div>
        <div className="col-md-6">
          <Stack spacing={2} direction="row-reverse">
            <Button
              variant="contained"
              startIcon={<AddOutlinedIcon />}
              onClick={handleAddLead}
              className={classes.leadButton}
            >
              Add Lead
            </Button>
            {
              auth.user !== CONNECTOR && <BulkUpload />
            }
          </Stack>
        </div>
      </div>

      <LeadsTable handleEditClick={handleEditClick} />

      {addLeadElement}

      {isLoading && <PageLoader />}
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
    </div>
  );
}

export default Leads;
