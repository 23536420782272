import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Select from "@mui/material/Select";
import React, { useContext } from "react";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import { bankNameConstants, productConstants, timeIntervalConstants } from "../../constants/appconstants";

const useStyles = makeStyles((theme)=>({
 
  root:{
    minWidth:'150px',
    marginLeft:'10px',
    backgroundColor: "#fff",
    [theme.breakpoints.only('xs')]:{
      minWidth:'130px', 
      margin:'10px'
    },
  }

}));

const TopButtons = (props) => {

  const classes=useStyles();

  const {
    freqValue,
    changeFreqValue,
    productType,
    changeProductType,
    bankName,
    changeBankName,
  } = useContext(AppContextProvider);

  const UpdateActiveButton = (freq) => {
    changeFreqValue(freq);
  };

  const handleChange = (event) => {
    changeProductType(event.target.value);
  };

  const handleBankName = (event) => {
    changeBankName(event.target.value);
  };

  return (
    <div>
      <div className={"row"}>
        <div className={"col-12 col-md-6"}>
          <button
            className="buttons-tabs button-active"
            // className={
            //   freqValue === MTD ? "buttons-tabs button-active" : "buttons-tabs"
            // }
            // value={MTD}
          >
            Current Month
          </button>
        </div>
        <div className="col-12 col-md-6">
        <FormControl
            sx={{ 
              display: "flex",
              flexDirection: "row-reverse",
              m: 0,
              backgroundColor: "#f8f9fd", 
              border: 0,
              outline: 0,
              // boxShadow: "10px 10px 6px -10px rgb(178 178 178 / 40%)",
            }}
            // style={{ float: "right" }}
            className="dropdown-products"
          >
            <Select
              value={productType}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              // sx={{
              //   minWidth: "150px",
              //   backgroundColor: "#fff",
              // }}
              className={classes.root}

            >
              <MenuItem value={""}>All Products</MenuItem>
              {Object.keys(productConstants).map((i) => (
                <MenuItem value={i} key={i}>{productConstants[i]}</MenuItem>
              ))}
            </Select>

            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={bankName}
              onChange={handleBankName}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={classes.root}
            >
              <MenuItem value={""}>Bank Name</MenuItem>
              {Object.keys(bankNameConstants)
                .sort()
                .map((x) => (
                  <MenuItem
                    key={bankNameConstants[x]}
                    value={bankNameConstants[x]}
                  >
                    {x}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TopButtons);
