import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { bankCodeConstants, productConstants, productOrder, stateConstants } from "../../constants/appconstants";
import { ApiUrl } from "../../helpers/getUrl";
import TransactionSection from "./stats";

function Reports() {

  const [level, setLevel] = useState('L1');

  const [productType, setProductType] = useState(undefined);
  const [loanBank, setLoanBank] = useState(undefined);
  const [loanState, setLoanState] = useState(undefined);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${ApiUrl()}reports/${productType}/${loanBank}/${loanState}`)
      .then(res => {
        let result = res.data;
        if (level === 'L1') {
          result.forEach((item) => item.index = productOrder[item.productType])
          result = result.sort((a, b) => b.activeBankCount - a.activeBankCount);
          setData(result);
        } else
          setData(result);
      }).catch(error => {
        console.error('There was an error!', error);
      });
  }, [level]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className="sidenav-content">
      <Grid container spacing={3} className="row m-0 sidenav-info">
        <TransactionSection />

        <div className="row ceo-reports-container">
          {level === 'L1' && <div>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">Master Report</Typography>
            </Breadcrumbs>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Master Report">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Type</TableCell>
                    <TableCell>Active Banks</TableCell>
                    <TableCell>Active States</TableCell>
                    <TableCell>Active Connectors</TableCell>
                    <TableCell>Number of Leads Submitted</TableCell>
                    <TableCell>Number of Leads Disbursed</TableCell>
                    <TableCell>Disbursment Amount</TableCell>
                    <TableCell>Number of Payouts</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      key={row.productType}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      hover
                      onClick={(event) => {
                        setLevel('L2');
                        setProductType(row.productType);
                      }}
                    >
                      <TableCell component="th" scope="row">{productConstants[row.productType]}</TableCell>
                      <TableCell>{row.activeBankCount}</TableCell>
                      <TableCell>{row.activeStateCount}</TableCell>
                      <TableCell>{row.activeConnectorCount}</TableCell>
                      <TableCell>{row.leadsSubmittedCount}</TableCell>
                      <TableCell>{row.leadsDisbursedCount}</TableCell>
                      <TableCell>{row.disbursedAmount}</TableCell>
                      <TableCell>{row.payoutCount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
          {level === 'L2' &&
            <div>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={() => {
                  setLevel('L1');
                  setProductType(undefined);
                }}>Master Report</Link>
                <Typography color="text.primary">{productConstants[productType]}</Typography>
              </Breadcrumbs>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="Master Report">
                  <TableHead>
                    <TableRow>
                      <TableCell>Loan Bank</TableCell>
                      <TableCell>Active States</TableCell>
                      <TableCell>Active Connectors</TableCell>
                      <TableCell>Number of Leads Submitted</TableCell>
                      <TableCell>Number of Leads Disbursed</TableCell>
                      <TableCell>Disbursment Amount</TableCell>
                      <TableCell>Number of Payouts</TableCell>
                      <TableCell>Payout Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow
                        key={row.loanBank}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        hover
                        onClick={(event) => {
                          setLevel('L3');
                          setLoanBank(row.loanBank);
                        }}
                      >
                        <TableCell component="th" scope="row">{bankCodeConstants[row.loanBank]}</TableCell>
                        <TableCell>{row.activeStateCount}</TableCell>
                        <TableCell>{row.activeConnectorCount}</TableCell>
                        <TableCell>{row.leadsSubmittedCount}</TableCell>
                        <TableCell>{row.leadsDisbursedCount}</TableCell>
                        <TableCell>{row.disbursedAmount}</TableCell>
                        <TableCell>{row.payoutCount}</TableCell>
                        <TableCell>{row.payoutAmount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>}
          {level === 'L3' && <div>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L1');
                setProductType(undefined);
                setLoanBank(undefined);
              }}>Master Report
              </Link>
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L2');
                setLoanBank(undefined);
              }}>{productConstants[productType]}
              </Link>
              <Typography color="text.primary">{bankCodeConstants[loanBank]}</Typography>
            </Breadcrumbs>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Master Report">
                <TableHead>
                  <TableRow>
                    <TableCell>Active States</TableCell>
                    <TableCell>Active Connectors</TableCell>
                    <TableCell>Number of Leads Submitted</TableCell>
                    <TableCell>Number of Leads Disbursed</TableCell>
                    <TableCell>Disbursment Amount</TableCell>
                    <TableCell>Number of Payouts</TableCell>
                    <TableCell>Payout Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      key={row.loanState}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      hover
                      onClick={(event) => {
                        setLevel('L4');
                        setLoanState(row.loanState);
                      }}
                    >
                      <TableCell component="th" scope="row">{stateConstants[row.loanState]}</TableCell>
                      <TableCell>{row.activeConnectorCount}</TableCell>
                      <TableCell>{row.leadsSubmittedCount}</TableCell>
                      <TableCell>{row.leadsDisbursedCount}</TableCell>
                      <TableCell>{row.disbursedAmount}</TableCell>
                      <TableCell>{row.payoutCount}</TableCell>
                      <TableCell>{row.payoutAmount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
          {level === 'L4' && <div>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L1');
                setProductType(undefined);
                setLoanBank(undefined);
                setLoanState(undefined);
              }}>Master Report
              </Link>
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L2');
                setLoanBank(undefined);
                setLoanState(undefined);
              }}>{productConstants[productType]}
              </Link>
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L3');
                setLoanState(undefined);
              }}>{bankCodeConstants[loanBank]}
              </Link>
              <Typography color="text.primary">{stateConstants[loanState]}</Typography>
            </Breadcrumbs>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Master Report">
                <TableHead>
                  <TableRow>
                    <TableCell>Active Connectors</TableCell>
                    <TableCell>Number of Leads Submitted</TableCell>
                    <TableCell>Number of Leads Disbursed</TableCell>
                    <TableCell>Disbursment Amount</TableCell>
                    <TableCell>Number of Payouts</TableCell>
                    <TableCell>Payout Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      key={row.loanState}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{row.connectorName}</TableCell>
                      <TableCell>{row.leadsSubmittedCount}</TableCell>
                      <TableCell>{row.leadsDisbursedCount}</TableCell>
                      <TableCell>{row.disbursedAmount}</TableCell>
                      <TableCell>{row.payoutCount}</TableCell>
                      <TableCell>{row.payoutAmount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
        </div>
      </Grid>
    </Container>
  );
}

export default Reports;
