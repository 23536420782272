import * as React from 'react';
import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import axios from "axios";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ApiUrl } from '../../helpers/getUrl';
import moment from 'moment';

export default function NotificationForm() {

    let [title, setTitle] = useState("");
    let [description, setDescription] = useState("");
    let [toDate, setToDate] = useState("");
    let [fromDate, setFromDate] = useState("");
    let [attachedFile, setAttachFile] = useState(null);
    let [open, setAlert] = useState(false);
    let [message, setMessage] = useState("");
    let [notistyle, setNotistyle] = useState({
        color: "var(--bs-primary)"
    });

    const localData = JSON.parse(localStorage.getItem('data'));
    const email = localData['finadminEmail'] || localData['ceoEmail'] || localData['finMngrEmail'] || "";

    const updval = (e, func) => {
        func(e.target.value);
    };

    const clearForm = () => {
        setTitle("");
        setDescription("");
        setToDate("");
        setFromDate("");
        setAttachFile(null);
        document.getElementById("fd").type = 'text';
        document.getElementById("ed").type = 'text';
    };

    const uploadAttachment = (file) => {
        const url = `${ApiUrl()}attachment/save`;
        const formData = new FormData();
        formData.append('file', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return axios.post(url, formData, config)
    }

    const submitNotification = (e) => {
        e.preventDefault();
        const now = new Date();
        const notifyDate = moment(fromDate).isSame(now, 'day') ? now.toISOString() : fromDate + "T00:00:00.000Z";
        const updateNotification = (attachmentId) => {
            let formData = {
                title,
                description,
                attachmentId: attachmentId,
                notifyOn: notifyDate,
                expiresOn: toDate + "T00:00:00.000Z",
                createdBy: email
            };
            axios.post(`${ApiUrl()}notification/save`, formData).then((res) => {
                if (res.data.acknowledged) {
                    clearForm();
                    alertClient("Notification sent successfully", {
                        color: "var(--bs-success)"
                    });
                } else {
                    alertClient("Some error occored please try again later..", {
                        color: "var(--bs-danger)"
                    });
                }
            }).catch((err) => {
                alertClient("Some error occored please try again later..", {
                    color: "var(--bs-danger)"
                });
            });
        };
        if (attachedFile) {
            uploadAttachment(attachedFile).then(res => {
                let data = res.data;
                updateNotification(data.attachmentId)
            });
        } else {
            updateNotification("");
        }
    };

    const focusDate = (e) => {
        console.log("focusDate", e);
        let flag = (e.target.id == document.activeElement.id);
        e.target.type = flag ? "date" : "text";
    }

    const alertClient = (msg, style) => {
        setNotistyle({
            ...notistyle,
            ...style
        });
        setMessage(msg);
        setAlert(true);
    };

    const handelDate = (date, func) => {
        let current = new Date();
        current = new Date(`${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`);
        let selected = new Date(date);
        let map = { from: setFromDate, to: setToDate };
        if (selected >= current) {
            if (func == "from") {
                if (selected <= new Date(toDate) || toDate == "") {
                    setFromDate(date);
                } else {
                    alertClient("From Date should be less than or equal to Expire Date", {
                        color: "var(--bs-danger)"
                    });
                    setFromDate("");
                }
            } else {
                if (new Date(fromDate) <= selected) {
                    setToDate(date);
                } else {
                    alertClient("From Date should be less than or equal to Expire Date", {
                        color: "var(--bs-danger)"
                    });
                    setToDate("");
                }
            }
        } else {
            map[func]("");
            alertClient("Select only current or future date", {
                color: "var(--bs-danger)"
            });
        }
    }

    const handelFileChange = (e) => {
        setAttachFile(e.target.files[0]);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return <Paper elevation={4} sx={{ padding: 5 }}>
        <h2 className={"text-center"}>Send Notifications</h2>
        <form onSubmit={(...arg) => submitNotification(...arg)} className={"row no-gutter"} method="post" >
            <TextField
                className={"col-12 mb-4"}
                label="Title"
                type="text"
                variant="standard"
                onChange={(e) => updval(e, setTitle)}
                value={title}
                required
            />
            <TextField
                className={"col-12 mb-4"}
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={6}
                onChange={(e) => updval(e, setDescription)}
                value={description}
                required
            />
            <div className={"row no-gutters"}>
                <TextField
                    type="text"
                    className={"col-5 mb-4"}
                    id="fd"
                    label="From Date"
                    onChange={(e) => updval(e, (d) => handelDate(d, 'from'))}
                    onFocus={(e) => focusDate(e)}
                    onBlur={(e) => focusDate(e)}
                    value={fromDate}
                    required
                />
                <div className={"col"}></div>
                <TextField
                    type="text"
                    className={"col-5 mb-4"}
                    id="ed"
                    label="Expire Date"
                    onChange={(e) => updval(e, (d) => handelDate(d, 'to'))}
                    onFocus={(e) => focusDate(e)}
                    onBlur={(e) => focusDate(e)}
                    value={toDate}
                    required
                />
            </div>
            <div className={"row"}>
                <Stack direction="row" alignItems="center" spacing={2} className={"col-6 mb-4"}>
                    <label htmlFor="contained-button-file">
                        <input style={{ display: 'none' }} id="contained-button-file" type="file" accept="image/png, image/gif, image/jpeg, image/jpg" onChange={handelFileChange} />
                        <Button variant='outlined' component='span' >
                            choose file here
                        </Button>
                    </label>
                </Stack>
                <div className={"col-6 mb-4"}>
                    {attachedFile && attachedFile.name || ""}
                </div>
            </div>
            <div className={"text-center"}>
                <Button variant='contained' type="submit" sx={{ width: '50%' }}>
                    Submit
                </Button>
            </div>
        </form>
        <Modal open={open} onClose={() => setAlert(false)}>
            <Box sx={modalStyle}>
                <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                    Alert
                </Typography>
                <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }} style={notistyle}>
                    {message}
                </Typography>
                <div className={"text-center mt-4"} >
                    <Button className={""} variant='outlined' component='span' onClick={() => setAlert(false)} >
                        Close
                    </Button>
                </div>
            </Box>
        </Modal>
    </Paper>
}