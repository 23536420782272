import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Login from "./features/login/Login";
import Register from "./features/Register/index";
import useAppContext from "./utils/useAppContext";
import NotFound from "./components/NotFoundPage/404-page";

import Routes from "./Routes";
import { userRoleConstants } from "./constants/appconstants";

import "./App.css";
import AadharDetails from "./features/LoanEnquiry/children/adharDetails";
import ResetPassword from "./features/login/resetPass";

export default function App() {
  let auth = useAppContext();

  let location = useLocation();
  let match = useRouteMatch();
  const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER } = userRoleConstants;

  console.log("location.pathname", location.pathname);
  if (location.pathname === "/") {
    if (auth.user == null) {
      return <Redirect to="/login" />;
    } else if (auth.user === CONNECTOR) {
      return <Redirect to={"DigiRep/home"} />;
    } else if (auth.user === FIN_ADMIN) {
      return <Redirect to={"admin/admin-home"} />;
    } else if (auth.user === CEO) {
      return <Redirect to={"admin/ceo-home"} />;
    } else if (auth.user === FIN_MANAGER) {
      return <Redirect to={"finmanager/home"} />;
    } else if (auth.user === CEO) {
      // return <Redirect to={"admin/admin-home"} />;
    }
  }

  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/registration" component={Register} />
      <Route path="/reset-pass" component={ResetPassword} />
      <PrivateRoute path={`${match.url}aadhar-details`} component={AadharDetails} />
      <PrivateRoute path={`${match.url}DigiRep`} component={Routes} />
      <PrivateRoute path={`${match.url}admin`} component={Routes} />
      <PrivateRoute path={`${match.url}ceo`} component={Routes} />
      <PrivateRoute path={`${match.url}finmanager`} component={Routes} />
      {/* <Route path="/" exact component={Dashboard} /> */}
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  let auth = useAppContext();
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
