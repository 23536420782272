import { yupResolver } from "@hookform/resolvers/yup";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
// import LeadsTable from "./LeadsTable";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import {
  ErrorSnackbar,
  SuccessSnackbar
} from "../../components/CustomSnackBar";
import PageLoader from "../../components/PageLoader";
import {
  bankNameConstants, loanStatusConstants, payoutStatusConstants, productConstants, redeemedStatusConstants
} from "../../constants/appconstants";
import { ApiUrl } from '../../helpers/getUrl';
import {
  postLeadsData,
  putLeadsData,
  resetLeads
} from "../../redux/reducers/LeadsDataService";
import PaymentApprovalTable from "./finManagerTable";

const validationSchema = yup.object({
  // Connector Details
  connectorName: yup.string().required("DigiRep Name is required"),
  connectorPhone: yup.string().required("DigiRep Phone is required"),
  customerName: yup.string().required("Customer Name is required"),
  lanNumber: yup.string().notRequired()
  .matches(/^[a-zA-Z0-9]{0,40}$/, "Must be alphanumeric"),
});
const onPickDate = (id) => {
  document.getElementById("ad").type = "date";
  document.getElementById("dd").type = "date";
}
const useStyles = makeStyles(() => ({
  tableCell: { textAlign: "center" },
  select: {
    width: "200px",
    margin: "5px",
  },
  // textField: {
  //   marginLeft: '30px'
  // },
  formControl: {
    padding: "5px",
    width: "250px",
  },
}));

function PaymentApproval() {
  const classes = useStyles();
  const { connectorId } = useContext(AppContextProvider);
  const dispatch = useDispatch(); // used to dispatch an action

  const [openLead, setOpenLead] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [formDataInEditMode, setFormDataInEditMode] = useState(null);

  const { isLoading, successMsg, errorMsg, invokeGetLeadsApi } = useSelector(
    ({ leadsDataService }) => leadsDataService
  );

  const resolver = yupResolver(validationSchema);

  let defaultValues = {
    connectorName: "",
    connectorPhone: "",
    connectorPayoutOption: "MONTHLY",
    leadManager: "",

    // customer details
    customerName: "",
    customerPhone: "",
    customerLocation: "",

    // Loan details
    productType: "",
    loanStatus: "OPEN",
    applicationNumber: "",
    loanBank: "",
    loanAmount: "",
    lanNumber: "",

    // Redeemed details
    bankPayoutAmount: 0,
    bankPayoutPercentage: 0,
    remarks: "",

    applicationDate: new Date(),
    disbursementAmount: 0,
    disbursementDate: "",

    connectorPayoutPercentage: 0,
    connectorPayoutAmount: 0,
    connectorPayoutDate: "",
  };

  if (isEditMode && formDataInEditMode?.leadId) {
    defaultValues = {
      connectorName: formDataInEditMode?.connectorName || "",
      connectorPhone: formDataInEditMode?.connectorPhone || "",
      connectorPayoutOption:
        formDataInEditMode?.connectorPayoutOption || "MONTHLY",
      leadManager: formDataInEditMode?.leadManager || "",

      // customer details
      customerName: formDataInEditMode?.customerName || "",
      customerPhone: formDataInEditMode?.customerPhone || "",
      customerLocation: formDataInEditMode?.customerLocation || "",

      // Loan details
      productType: formDataInEditMode?.productType || "",
      loanStatus: formDataInEditMode?.loanStatus || "OPEN",
      applicationNumber: formDataInEditMode?.applicationNumber || "",
      loanBank: formDataInEditMode?.loanBank || "",
      loanAmount: formDataInEditMode?.loanAmount || "",
      lanNumber: formDataInEditMode?.lanNumber || "",

      // Redeemed details
      bankPayoutAmount: formDataInEditMode?.bankPayoutAmount || 0,
      bankPayoutPercentage: formDataInEditMode?.bankPayoutPercentage || 0,
      remarks: formDataInEditMode?.remarks || "",

      applicationDate: formDataInEditMode?.applicationDate || new Date(),
      disbursementAmount: formDataInEditMode?.disbursementAmount || 0,
      disbursementDate: formDataInEditMode?.disbursementDate || "",

      connectorPayoutPercentage:
        formDataInEditMode?.connectorPayoutPercentage || 0,
      connectorPayoutAmount: formDataInEditMode?.connectorPayoutAmount || 0,
      connectorPayoutDate: formDataInEditMode?.connectorPayoutDate || "",
    };
  }

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (invokeGetLeadsApi) {
      setOpenLead(false);
      reset();
      setTimeout(() => {
        dispatch(resetLeads());
      }, 2000);
    }
    return () => { };
  }, [invokeGetLeadsApi]);

  const handleAddLead = () => {
    setOpenLead(true);
    setEditMode(false);
    setFormDataInEditMode(null);
    reset(defaultValues);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "connector-payout.csv"


    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch(ApiUrl() + 'redemption/download', requestOptions)
      .then(async response => {
        const blob = await response.blob();
        const newBlob = new Blob([blob]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `connector-payout-${new Date().toLocaleDateString()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blob);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  const handleEditClick = (data) => {
    setOpenLead(true);
    setEditMode(true);
    setFormDataInEditMode(data);
  };

  const handleClose = () => {
    setOpenLead(false);
  };

  const onSubmit = (data) => {
    let leadsData = {
      ...data,
      applicationDate: "2021-10-02",
      connectorId,
      createdBy: "mkhati@gmail.com",
      sourceType: "INTERNAL",
    };

    console.log(leadsData, ":leadsData in admin");

    if (isEditMode) {
      dispatch(putLeadsData(formDataInEditMode?.leadId, data));
    } else {
      dispatch(postLeadsData({ ...leadsData }));
    }
  };

  // useEffect(() => {
  //   dispatch(getLeadsData()); //Invoke Action
  // }, []);

  const addLeadElement = (
    <Dialog onBackdropClick="false" open={openLead} onClose={handleClose}>
      <DialogTitle>Lead Entry Module - DigiRep</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ paddingTop: 0, marginTop: "-20px" }}>
          <DialogTitle>DigiRep Details</DialogTitle>
          <div className="row form-fields mt-md-n5">
            {/* Connector Details */}
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("connectorName")}
                  label="DigiRep Name"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorName && errors?.connectorName}
                  helperText={errors?.connectorName?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("connectorPhone")}
                  label="DigiRep Phone"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorPhone && errors?.connectorPhone}
                  helperText={errors?.connectorPhone?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel>Payout Option</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "20px" }}
                  {...register("connectorPayoutOption")}
                  label="DigiRep Payout Option"
                  fullWidth
                  defaultValue={watch("connectorPayoutOption")}
                >
                  {Object.keys(payoutStatusConstants).map((x) => (
                    <MenuItem value={x}>{payoutStatusConstants[x]}</MenuItem>
                  ))}
                </Select>
                {errors?.payOutType?.message && (
                  <FormHelperText
                    error={errors?.payOutType && errors?.payOutType}
                  >
                    {errors?.payOutType?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("leadManager")}
                  label="Connector Manager"
                  fullWidth
                  variant="standard"
                  error={errors?.leadManager && errors?.leadManager}
                  helperText={errors?.leadManager?.message}
                />
              </FormControl>
            </div>
            {/* Connector Details End*/}

            {/* Customer Details */}
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("customerName")}
                  label="Customer Name"
                  fullWidth
                  variant="standard"
                  error={errors?.customerName && errors?.customerName}
                  helperText={errors?.customerName?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("customerPhone")}
                  label="Mobile Number"
                  fullWidth
                  variant="standard"
                  error={errors?.customerPhone && errors?.customerPhone}
                  helperText={errors?.customerPhone?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("customerLocation")}
                  label="Customer Location"
                  fullWidth
                  variant="standard"
                  error={errors?.customerLocation}
                  helperText={errors?.customerLocation?.message}
                />
              </FormControl>
            </div>
            {/* Customer Details End*/}

            {/* Loan Details Start */}
            <DialogTitle>Loan Details</DialogTitle>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel id={"label-Product Type"}>
                  {"Product Type"}
                </InputLabel>
                <Select
                  // error={errors?.productType && errors?.productType}
                  className={classes.textField}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "20px" }}
                  {...register("productType")}
                  label="Product Type"
                  defaultValue={watch("productType")}
                >
                  {Object.keys(productConstants)?.map((x) => (
                    <MenuItem value={x}>{productConstants[x]}</MenuItem>
                  ))}
                </Select>
                {errors?.productType?.message && (
                  <FormHelperText
                    error={errors?.productType && errors?.productType}
                  >
                    {errors?.productType?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel id={"label-Loan Status"}>
                  {"Loan Status"}
                </InputLabel>
                <Select
                  // error={errors?.productType && errors?.productType}
                  className={classes.textField}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "20px" }}
                  {...register("loanStatus")}
                  label="Loan Status"
                  defaultValue={watch("loanStatus")}
                >
                  {Object.keys(loanStatusConstants)?.map((x) => (
                    <MenuItem value={x}>{loanStatusConstants[x]}</MenuItem>
                  ))}
                </Select>
                {errors?.loanStatus?.message && (
                  <FormHelperText
                    error={errors?.loanStatus && errors?.loanStatus}
                  >
                    {errors?.loanStatus?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("applicationNumber")}
                  label="Application Number"
                  variant="standard"
                  error={errors?.applicationNumber && errors?.applicationNumber}
                  helperText={errors?.applicationNumber?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <input id="dd" placeholder="Disbursed Date" className="date-picker" type="text" onFocus={onPickDate} />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <input id="ad" placeholder="Application Date" className="date-picker" type="text" onFocus={onPickDate} />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                {/* <InputLabel>Bank Name</InputLabel> */}
                <Autocomplete
                  disablePortal
                  options={Object.keys(bankNameConstants)}
                  id="demo-simple-select-standard"
                  renderInput={(params) => <TextField
                    {...register("loanBank")}
                    {...params} label="Bank Name" />}
                />
                {/* <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "20px" }}
                  {...register("loanBank")}
                  label="Bank Name"
                  defaultValue={watch("loanBank")}
                >
                  {Object.keys(bankNameConstants)
                    .sort()
                    .map((x) => (
                      <MenuItem value={bankNameConstants[x]}>{x}</MenuItem>
                    ))}
                </Select> */}
                {errors?.loanBank?.message && (
                  <FormHelperText error={errors?.loanBank && errors?.loanBank}>
                    {errors?.loanBank?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  type="number"
                  {...register("loanAmount")}
                  label="Loan Amount"
                  fullWidth
                  InputProps={{ inputProps: { min: 0 } }}
                  variant="standard"
                  error={errors?.loanAmount && errors?.loanAmount}
                  helperText={errors?.loanAmount?.message}
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  type="text"
                  {...register("lanNumber")}
                  label="Loan Account Number (LAN)"
                  fullWidth
                  variant="standard"
                  error={errors?.lanNumber && errors?.lanNumber}
                  helperText={errors?.lanNumber?.message}
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("disbursementAmount")}
                  label="Disbursed Amount"
                  fullWidth
                  variant="standard"
                  error={
                    errors?.disbursementAmount && errors?.disbursementAmount
                  }
                  helperText={errors?.disbursementAmount?.message}
                />
              </FormControl>
            </div>

            {/* <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("disbursementDate")}
                  label="Disbursed Month"
                  fullWidth
                  variant="standard"
                  error={errors?.disbursementDate && errors?.disbursementDate}
                  helperText={errors?.disbursementDate?.message}
                />
              </FormControl>
            </div> */}

            {/* Loan Details END */}

            {/* Reedemed Detailss Start */}
            <DialogTitle>Reedemed Details</DialogTitle>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel>Status</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "30px" }}
                  {...register("paymentStatus")}
                  fullWidth
                  defaultValue={watch("paymentStatus")}
                >
                  {Object.keys(redeemedStatusConstants).map((x) => (
                    <MenuItem value={x}>{redeemedStatusConstants[x]}</MenuItem>
                  ))}
                  <MenuItem value={"status"}>Status</MenuItem>
                </Select>
                {errors?.paymentStatus?.message && (
                  <FormHelperText
                    error={errors?.paymentStatus && errors?.paymentStatus}
                  >
                    {errors?.paymentStatus?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("bankPayoutAmount")}
                  label="Bank Payout Amount"
                  fullWidth
                  variant="standard"
                  error={errors?.bankPayoutAmount && errors?.bankPayoutAmount}
                  helperText={errors?.bankPayoutAmount?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("bankPayoutPercentage")}
                  label="Percentage"
                  fullWidth
                  variant="standard"
                  error={
                    errors?.bankPayoutPercentage && errors?.bankPayoutPercentage
                  }
                  helperText={errors?.bankPayoutPercentage?.message}
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("reedemedAmount")}
                  label="Amount"
                  fullWidth
                  variant="standard"
                  error={errors?.reedemedAmount && errors?.reedemedAmount}
                  helperText={errors?.reedemedAmount?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("reedemBankPayoutPct")}
                  label="Bank Payout Pct"
                  fullWidth
                  variant="standard"
                  error={
                    errors?.reedemBankPayoutPct && errors?.reedemBankPayoutPct
                  }
                  helperText={errors?.reedemBankPayoutPct?.message}
                />
              </FormControl>
            </div>

            <br></br>
            <div className="col-md-12">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("remarks")}
                  label="Remarks"
                  fullWidth
                  variant="standard"
                  error={errors?.remarks && errors?.remarks}
                  helperText={errors?.remarks?.message}
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "0 30px 20px 0" }}>
          <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.leadButton}
            type="submit"
            disabled={isLoading ? "true" : "false"}
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <div className="sidenav-content">
      <div className="row my-2">
        <div className="col-md-6">
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="leads-heading"
          >
            Leads Data
          </Typography>
        </div>
        <div className="col-md-6">
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={handleDownload}
            className="float-end"
          >
            Download
          </Button>
          {/* <Stack spacing={2} direction="row-reverse">
            <Button
              variant="contained"
              startIcon={<AddOutlinedIcon />}
              onClick={handleAddLead}
              className={classes.leadButton}
            >
              Add Lead
            </Button>

            <Button
              sx={{ boxShadow: 2 }}
              variant="text"
              endIcon={<FileUploadOutlinedIcon />}
            >
              Bulk Upload
            </Button>
          </Stack> */}
        </div>
      </div>

      <PaymentApprovalTable handleEditClick={handleEditClick} />

      {addLeadElement}

      {isLoading && <PageLoader />}
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
    </div>
  );
}

export default PaymentApproval;
