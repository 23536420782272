import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "../../../components/contextProvider/AppContextProvider/AppContext";
import { fetchPeriodicProjections } from "../../../redux/reducers/PeriodicProjection";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";


function Chart1() {
  const dispatch = useDispatch();

  const { freqValue, productType } = useContext(AppContext);
  const data = JSON.parse(localStorage.getItem('data'));
  const connectorId = data?.connectorId;

  const { periodicProjectionsData, isLoading, successMsg, errorMsg } =
    useSelector(({ periodicProjectionService }) => periodicProjectionService);

  useEffect(() => {
    dispatch(fetchPeriodicProjections(connectorId, productType, freqValue)); //Invoke Action
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freqValue, productType, connectorId]);

  const data1 = [];
  // { name: "CC", submitted: 4000, processed: 2400 },

  let temp = {};

  const dummy = periodicProjectionsData.map((x) => {
    temp.name = x.label
    temp.Submitted = x.totalApplications
    temp.Processed = x.totalDisbursedApplications

    data1.push(temp)
    temp = {}
  })
  
  return (
    <ResponsiveContainer>
      <BarChart
        data={data1}
        margin={{ top: 30, bottom: 30, left: 15, right: 15 }}
      >
        <CartesianGrid />
        <XAxis dataKey="name" allowDecimals={false} domain= {['', '']}  />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend />
        <Bar barSize={15} dataKey="Submitted" fill="#EF96B4" />
        <Bar barSize={15} dataKey="Processed" fill="#68CAFB" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Chart1;
