import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { bankNameConstants } from "../../constants/appconstants";
import { addNewAccountAPI, deleteExistingBankAccount, updateExistingAccountAPI } from "../../redux/reducers/LeadsDataService";
import BanksList from "./BanksList";
import { ApiUrl } from '../../helpers/getUrl';

const validationSchema = yup.object({
    accountHolderName: yup.string().required("Account Holder name is required"),
    accountNumber: yup.string().required("Account Number is required"),
    bankName: yup.string().required("Bank Name is required"),
    ifsc: yup.string().test(
        "len",
        "Please enter valid IFSC Code",
        (val) => val.toString().length === 11
    ).required("IFSC Code is required"),
    pan: yup.string().required("PAN Number is required"),
    // connectorId: 1001,//localStorage.userId,
    amount: yup.string().required("Gross Amount is required")
});

const useYupValidationResolver = (validationSchema) =>
    useCallback(
        async (data) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );



const useStyles = makeStyles(() => ({
    tableCell: { textAlign: "center" },
    select: {
        width: "200px",
        margin: "5px",
    },
    // textField: {
    //   marginLeft: '30px'
    // },
    formControl: {
        padding: "5px",
        width: "250px",
    },
}));

export default function RedemptionManagement() {

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [showAddNewAccount, setShowAddNewAccount] = useState(false);
    const [editingFlag, setEditingFlag] = useState(false);
    const [showDelete, setShowDelete] = useState({ flag: false, delete_row: {} });
    const classes = useStyles();
    const [formDataInEditMode, setFormDataInEditMode] = useState(null);
    const dispatch = useDispatch();
    const resolver = useYupValidationResolver(validationSchema);

    // useEffect(() => {

    // }, [])

    const addNewAccount = () => {
        setShowAddNewAccount(true)
        setEditingFlag(false)
    };



    let defaultValues = {
        accountHolderName: '',
        accountNumber: '',
        bankName: '',
        ifsc: '',
        pan: '',
        amount: '',
    };

    if (editingFlag && formDataInEditMode) {
        defaultValues = {
            ...defaultValues,
            ...formDataInEditMode
        };
    }

    const {
        handleSubmit,
        register,
        control,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver,
    });



    useEffect(() => {
        reset(defaultValues);
    }, [editingFlag]);

    const handleClose = (event, reason) => {
        if (!['backdropClick', 'escapeKeyDown'].includes(reason)) {
            setEditingFlag(false);
            setShowAddNewAccount(false);
            reset();
            setIsFilePicked(false);
            setSelectedFile(null);
        }
    };

    const handleDeleteClose = () => {
        setShowDelete({ flag: false, delete_row: {} });
    }

    const onSubmit = (data) => {
        setShowAddNewAccount(false);
        const {
            accountHolderName,
            accountNumber,
            bankName,
            ifsc,
            pan,
            connectorId,
            amount,
            accountId,
            createdBy } = data;

        let payload = {
            accountHolderName,
            accountNumber,
            bankName,
            ifsc,
            pan,
            connectorId: parseInt(localStorage.connectorId),
            amount,
            createdBy
        }
        if (editingFlag) {
            dispatch(updateExistingAccountAPI(accountId, payload, selectedFile));
            setEditingFlag(false);
            setIsFilePicked(false);
        } else {
            dispatch(addNewAccountAPI(payload, selectedFile));
        }
        reset();
    }

    const handleEditClick = (row) => {
        defaultValues = { ...row };
        setEditingFlag(true);
        setShowAddNewAccount(true);
        setFormDataInEditMode(row);
        setSelectedFile(null);
        setIsFilePicked(false);
    }

    const handleDeleteClick = (row) => {
        setShowDelete({ flag: true, delete_row: row });
    }

    const submitDelete = () => {
        const { accountId } = showDelete.delete_row
        dispatch(deleteExistingBankAccount(accountId));
        setShowDelete({ flag: false, delete_row: {} });
    }

    const changeHandler = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setIsFilePicked(true);
    };

    const downloadFile = () => {
        const attachmentId = defaultValues.attachmentId;
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = defaultValues.fileName;


        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(ApiUrl() + `accounts/documents/${attachmentId}`, requestOptions)
            .then(async response => {
                const blob = await response.blob();
                const newBlob = new Blob([blob]);
                const blobUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', defaultValues.fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(blob);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const addNewAccountElement = (
        <Dialog open={showAddNewAccount} onClose={handleClose}>
            <DialogTitle>{editingFlag ? 'Update' : 'Add'} Account</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent sx={{ paddingTop: 0, marginTop: "-20px" }}>
                    <div className="row">
                        <div className="col-md-6">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    margin="dense"
                                    {...register("accountHolderName")}
                                    label="Account Holder Name"
                                    fullWidth
                                    variant="standard"
                                    // defaultValue={watch('accountHolderName')}
                                    error={errors?.accountHolderName && errors?.accountHolderName}
                                    helperText={errors?.accountHolderName?.message}
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    // autoFocus
                                    margin="dense"
                                    {...register("pan")}
                                    label="PAN Number"
                                    fullWidth
                                    variant="standard"
                                    error={errors?.pan && errors?.pan}
                                    helperText={errors?.pan?.message}
                                />
                            </FormControl>
                        </div>

                        <div className="col-md-6">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    margin="dense"
                                    {...register("accountNumber")}
                                    label="Account Number"
                                    fullWidth
                                    variant="standard"
                                    error={errors?.accountNumber && errors?.accountNumber}
                                    helperText={errors?.accountNumber?.message}
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <FormControl variant="standard" className={classes.formControl}>
                                <Autocomplete
                                    options={Object.keys(bankNameConstants)}
                                    id="demo-simple-select-standard"
                                    renderInput={(params) => <TextField
                                        {...register("bankName")}
                                        {...params} label="Bank Name" />}
                                    defaultValue={watch("bankName")}
                                />
                                {errors?.bankName?.message && (
                                    <FormHelperText error={errors?.bankName && errors?.bankName}>
                                        {errors?.bankName?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    //   type="number"
                                    margin="dense"
                                    {...register("ifsc")}
                                    label="IFSC Code"
                                    fullWidth
                                    variant="standard"
                                    error={errors?.ifsc && errors?.ifsc}
                                    helperText={errors?.ifsc?.message}
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    type="number"
                                    margin="dense"
                                    {...register("amount")}
                                    label="Gross Amount"
                                    fullWidth
                                    variant="standard"
                                    error={errors?.amount && errors?.amount}
                                    helperText={errors?.amount?.message}
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-12 mt-3">
                            {defaultValues.fileName ?
                                <><Alert severity="info" action={
                                    <Button color="inherit" size="small" onClick={downloadFile}>
                                        Download
                                    </Button>
                                }>
                                    <AlertTitle>{defaultValues.fileName}</AlertTitle>
                                </Alert>
                                    <br />
                                </>
                                :
                                <>
                                    {isFilePicked ?
                                        <Alert icon={<CheckIcon fontSize="inherit" />} onClose={() => { setIsFilePicked(false); setSelectedFile(null); }}>{selectedFile?.name}</Alert>
                                        :
                                        <>
                                            <Button variant="outlined" aria-required component="label">
                                                <input required type="file" onChange={changeHandler} />
                                            </Button>
                                            {/* {
                                                errors.panDoc && touched.panDoc
                                                    ? <p className="errors">{errors.panDoc.message}</p>
                                                    : null
                                            } */}

                                            {/* <p style={{ color: "#d32f2f" }}>{errors?.panDoc?.message}</p> */}
                                        </>

                                    }
                                </>
                            }

                        </div>
                    </div>
                </DialogContent>
                <DialogActions sx={{ padding: "0 30px 20px 0" }}>
                    <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.leadButton}
                        type="submit"
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );

    const deleteConfirm = (
        <Dialog onBackdropClick="false" fullWidth={'lg'} open={showDelete.flag} onClose={() => setShowDelete({ flag: false, delete_row: {} })}>
            <DialogTitle>Delete Account</DialogTitle>
            <DialogContent lg>
                <div className="row form-fields">
                    <div className="col-md-12">
                        Are you sure you want to delete this account?
                    </div>
                </div>
            </DialogContent>
            <DialogActions sx={{ padding: "0 30px 20px 0" }}>
                <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleDeleteClose}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={classes.leadButton}
                    type="submit"
                    onClick={() => { submitDelete() }}
                >
                    Confirm
                </Button>
            </DialogActions>
            {/* </form> */}
        </Dialog>
    )

    return (
        <div>
            {addNewAccountElement}
            {deleteConfirm}
            <div className="sidenav-content">
                <div className="row my-2">
                    <div className="col-md-6">
                        <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            className="leads-heading"
                        >
                            Manage Accounts
                        </Typography>
                    </div>
                    <div className="col-md-6">
                        <Stack spacing={2} direction="row-reverse">
                            <Button
                                variant="contained"
                                startIcon={<AddOutlinedIcon />}
                                onClick={addNewAccount}
                                className={classes.leadButton}
                            >
                                Add New Account
                            </Button>
                        </Stack>
                    </div>
                </div>
                <BanksList
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                />
            </div>
        </div>
    )
}
