import img1 from "./Slide1.png";
import img2 from "./Slide2.png";
import img3 from "./Slide3.png";
import img5 from "./map.gif";
import img4 from "./map1.gif";
import chairman from "./chairman.png";
import ceo from "./CEO.png";
import impetus from "./impetus.png";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";


export default function BannerLeft() {
  return (
    <>
      <Grid item sm={7} sx={{ display: { xs: 'none', sm: 'block' } }} style={{ backgroundColor: '#1976d2', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h4"
          color="white"
          fontFamily="IBM Plex Serif"
          sx={{ ml: 4, my: 2, fontWeight: "700" }}
        >
          Impetus Solutions
        </Typography>
        <img src={img4} style={{ height: '55vh', width: '90%', display: 'block', margin: 'auto', borderRadius: '32px' }} />
        {/* <Carousel autoPlay={true} interval={3000} showArrows={false} showThumbs={false} dynamicHeight={false} infiniteLoop={true}></Carousel> */}
        <Grid container spacing={2} sx={{ p: 4 }} style={{ background: 'linear-gradient(180deg, rgba(0, 33, 105, 0) 0%, #002169 100%)' }}>
          <Grid item lg={6} sm={6}>
            <Paper
              sx={{
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 1,
                backgroundColor: "#1B82D6",
              }}
            >
              <img
                style={{ maxHeight: "140px", width: '33.33%' }}
                src={chairman}

              />
              <div>
                <Typography
                  variant="h5"
                  color="white"
                  sx={{ ml: 3, fontSize: '1rem', fontWeight: "700" }}
                >
                  B.Ramakrishna (Shiva)
                </Typography>
                <Typography
                  variant="h6"
                  color="white"
                  sx={{ ml: 3, fontSize: '.75rem', fontWeight: "200" }}
                >
                  Founder & Chairman, Star Powerz
                </Typography>
              </div>
            </Paper>
          </Grid>

          <Grid item lg={6} sm={6}>
            <Paper
              sx={{
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 1,
                backgroundColor: "#1B82D6",
              }}
            >
              <img
                style={{ maxHeight: "140px", width: '33.33%' }}
                src={ceo}
              />
              <div>
                <Typography
                  variant="h5"
                  color="white"
                  sx={{ ml: 3, fontSize: '1rem', fontWeight: "700" }}
                >
                  B.Venkateswara Rao (Venkat)
                </Typography>
                <Typography
                  variant="h6"
                  color="white"
                  sx={{ ml: 3, fontSize: '.75rem', fontWeight: "200" }}
                >
                  CEO, Starpowerz
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid container justifyContent="flex-end" sx={{ p: 1, mt: '2px' }}>
            <Paper
              sx={{
                borderRadius: '20px',
                float: 'end',
                p: 1,
                backgroundColor: "#000000",

              }}
            >
              <div>
                <Typography
                  variant="h6"
                  color="white"
                  sx={{ ml: 3, fontSize: '.75rem', fontWeight: "200" }}
                >
                  Powered By <img
                    style={{ maxHeight: "200px", width: '33.33%' }}
                    src={impetus}
                  />
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
