import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { useLocation, Link } from "react-router-dom";
import { Box } from "@mui/material";

const active = {
  background: "white",
  borderRadius: "5px",
  borderRight: 6,
  borderColor: "#40c4ff",
  "&:hover": {
    background: "white",
  },
};
const nonActive = { borderRadius: "5px", color: "white" };

export default function DigiRepList({handleDrawerToggle}) {
  let location = useLocation();

  const { pathname } = location;
  return (
    <Box>
      <List>
        <ListItem
          component={({ innerRef, ...props }) => <Link {...props} to="home" />}
        >
          <ListItemButton
            sx={
              pathname === "/DigiRep/home" || pathname === "/DigiRep/"
                ? active
                : nonActive
            }
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/DigiRep/home" || pathname === "/DigiRep/"
                  ? {}
                  : { color: "white" }
              }
            >
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => <Link {...props} to="leads" />}
        >
          <ListItemButton
            sx={pathname === "/DigiRep/leads" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/DigiRep/leads" ? {} : { color: "white" }}
            >
              <PersonOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Leads" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="redemption-management" />
          )}
        >
          <ListItemButton
            sx={pathname === "/DigiRep/redemption-management" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/DigiRep/redemption-management" ? {} : { color: "white" }
              }
            >
              <FactCheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Accounts" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="loan-enquiry" />
          )}
        >
          <ListItemButton
            sx={pathname === "/DigiRep/loan-enquiry" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/DigiRep/loan-enquiry" ? {} : { color: "white" }
              }
            >
              <FactCheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Enquiry" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="settings" />
          )}
        >
          <ListItemButton
            sx={pathname === "/DigiRep/settings" ? active : nonActive}
          >
            <ListItemIcon
              sx={pathname === "/DigiRep/settings" ? {} : { color: "white" }}
            >
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );
}
