import { yupResolver } from "@hookform/resolvers/yup";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as yup from "yup";
import CmtDropdownMenu from "../../@coremat/CmtDropdownMenu";
import { bankCodeConstants, bankNameConstants, stateConstants, stateNameToCodeMapping } from "../../constants/appconstants";
import { ApiUrl } from '../../helpers/getUrl';
import { updateUserDetails } from "../../redux/reducers/UserActionsService";
import useAppContext from "../../utils/useAppContext";


const useStyles = makeStyles((theme) => ({
  profileRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 10,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 20,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
    },
  },
  profileContent: {
    display: "inline-flex",
    alignItems: "center",
  },
  title: {
    padding: "0 0 0 7px",
    fontSize: 14,
    fontWeight: 500,
    color: "#000",
    textTransform: 'capitalize'
  },
  formControl: {
    padding: "5px",
    width: "100%",
  },
}));


const actionsList = [
  {
    icon: <AccountCircleIcon />,
    label: "Update Profile",
  },
  {
    icon: <HttpsOutlinedIcon />,
    label: "Change Password",
  },
  {
    icon: <ExitToAppIcon />,
    label: "Logout",
  },
];

const UserDropDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let auth = useAppContext();
  const phoneRegExp = new RegExp('[0-9]{10}');

  const validationSchema = yup.object({
    oldPassword: yup.string().required("Old Password is required"),
    newPassword: yup.string().min(8, "Must be at least 8 characters").max(32).required("New Password is required"),
    confirmNewPassword: yup.string().required("Confirm Password is required")
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
    // userPhone: yup.string().required("Phone is Required").matches(phoneRegExp,'Must be a valid phone'),
  });

  const resolver = yupResolver(validationSchema);
  const [showUpdateProfile, showUpdateProfileSetter] = useState(false);
  const [showUpdateProfileAdmin, showUpdateProfileSetterAdmin] = useState(false);
  const [showChangePassword, showChangePasswordSetter] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [formDataInEditMode, setFormDataInEditMode] = useState(null);
  const [formData, setFormData] = useState({});
  const [checkData, setCheckData] = useState(null);
  const [finadminName, setFinadminName] = useState('');
  const [finadminPhone, setFinadminphone] = useState('');
  const [finadminEmail, setFinadminEmail] = useState('');
  const [getConnectorDetails, setgetConnectorDetails] = useState(false);
  const [message, setMessage] = useState(null);
  const [isloadData, setIsloadData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  let localData = JSON.parse(localStorage.getItem('data'));

  const { connectorId, finadminId, finMngrId } = localData || {};

  let userIdEdit;
  const userRole = user.userRole;

  useEffect(() => {
    if (connectorId && getConnectorDetails) {
      axios.get(`${ApiUrl()}connector/${connectorId}`)
        .then(res => {
          localStorage.setItem("data", JSON.stringify(res.data));
          localData = JSON.parse(localStorage.getItem('data'));
          setgetConnectorDetails(false);
        }).catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, [getConnectorDetails]);

  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 2000);
  }, [showUpdateProfileAdmin]);

  if (connectorId) {
    userIdEdit = connectorId;
  } else if (finadminId) {
    userIdEdit = finadminId;
  } else if (finMngrId) {
    userIdEdit = finMngrId;
  }


  useEffect(() => {
    console.log(connectorId, finadminId, finMngrId);
    console.log(userRole);

  }, []);

  let defaultValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",

    //update profile
    userFirstName: "",
    userPhone: "",
    userEmail: "",
    connectorManager: "",
    accountNumber: "",
    bankName: "",
    IFSC_Code: "",
    loanState: "",
    location: "",



  }


  if (isEditMode && formDataInEditMode) {
    defaultValues = {
      userFirstName: formDataInEditMode?.accountHolderName || "",
      userPhone: formDataInEditMode?.connectorPhone || "",
      userEmail: formDataInEditMode?.connectorEmail || "",
      connectorManager: formDataInEditMode?.connectorManager || "",
      accountNumber: formDataInEditMode?.accountNumber || "",
      bankName: formDataInEditMode?.bankName || "",
      IFSC_Code: formDataInEditMode?.IFSC_Code || "",
      location: formDataInEditMode?.location || "",
      loanState: formDataInEditMode?.loanState || "",

    };


  }

  const adminNameUpdate = (event) => {
    setFinadminName(event.target.value);
  }
  const adminPhoneUpdate = (event) => {
    setFinadminphone(event.target.value);
  }
  const adminEmailUpdate = (event) => {
    setFinadminEmail(event.target.value);
  }


  const handleEditClick = () => {
    setEditMode(true);
    axios.get(`${ApiUrl()}finadmin/${finadminId}`)
      .then(res => {

        setFinadminName(res.data.finadminName);
        setFinadminEmail(res.data.finadminEmail);
        setFinadminphone(res.data.finadminPhone);
        setCheckData(res.data);
        console.log(res.data);
        console.log(checkData);

      })


  }

  const handleClose = (event, reason) => {
    if (!['backdropClick', 'escapeKeyDown'].includes(reason)) {
      showUpdateProfileSetter(false);
      showUpdateProfileSetterAdmin(false);
      showChangePasswordSetter(false);
      reset();
      setMessage(null);
    }
  };

  const submitUpdateProfileAdmin = () => {

    if (connectorId) {
      const elements = ["accountHolderName", "connectorPhone", "connectorManager", "state", "location"];
      let data;
      elements.forEach((item) => {
        if (localData[item] !== document.getElementById(item)?.value) {
          data = {
            ...data,
            [item]: item === "state" ? stateNameToCodeMapping[document.getElementById(item)?.value] : document.getElementById(item)?.value,
          }
        }
      })
      data = {
        ...data,
        updatedBy: document.getElementById("connectorEmail").value
      }

      if (Object.keys(data).length > 1) {
        axios.put(`${ApiUrl()}connector/${localData.connectorId}`, data)
          .then(res => {
            if (res.status === 200) {
              setgetConnectorDetails(true);
              setMessage({
                severity: 'success',
                title: 'Profile updated',
                description: 'Connector profile has been updated successfully.'
              });
              reset();
            } else {
              setMessage({
                severity: 'error',
                title: 'Error',
                description: res.message
              });
            }
          }).catch(error => {
            console.log(error)
            setMessage({
              severity: 'error',
              title: 'Error',
              description: 'Something Went wrong'
            });
          });
      }
    } else {
      const data = {};
      if (finadminName !== checkData.finadminName) {
        data.finadminName = finadminName;
      }
      if (finadminPhone !== checkData.finadminPhone) {
        data.finadminPhone = finadminPhone;
      }
      if (finadminEmail !== checkData.finadminEmail) {
        data.finadminEmail = finadminEmail;
      }

      axios.put(`${ApiUrl()}finadmin/${finadminId}`, data)
        .then(res => {
          if (res.status === 200) {
            setMessage({
              severity: 'success',
              title: 'Profile updated',
              description: 'Admin profile has been updated successfully.'
            });
            showUpdateProfileSetterAdmin(false);
            let localUserData = JSON.parse(localStorage.getItem("user"))
            localUserData = {
              userId: localUserData.userId,
              userRole: localUserData.userRole,
              userEmail: data?.finadminEmail || localUserData.userEmail,
              userName: data?.finadminName || localUserData.userName,
              userPhone: data?.finadminPhone || localUserData.userPhone
            };
            localStorage.setItem("user", JSON.stringify(localUserData));
            reset();
          } else {
            setMessage({
              severity: 'error',
              title: 'Error',
              description: res.message
            });
          }
        }).catch(error => {
          console.log(error)
          setMessage({
            severity: 'error',
            title: 'Error',
            description: 'Try again after some time'
          });
        });
    }
    showUpdateProfileSetterAdmin(false);
  }

  const submitPasswordChange = (data) => {
    const { oldPassword, newPassword } = data;
    const param = { oldPassword, newPassword };

    axios.put(`${ApiUrl()}auth/update/${user.userId}`, param)
      .then(res => {
        setMessage({
          severity: 'success',
          title: 'Password Changed',
          description: 'Your password has been changed successfully.'
        });
        reset();
      }).catch(error => {
        setMessage({
          severity: 'error',
          title: 'Error',
          description: error.response.data.message
        });
      });
  }

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver,
  });

  const onItemClick = (item) => {
    const { label } = item
    if (label === "Logout") {
      auth.signout();
    } else if (label === 'Update Profile') {
      if (userRole === "FIN_ADMIN") {
        showUpdateProfileSetterAdmin(true);
        handleEditClick();
      } else if (userRole === "CONNECTOR") {
        setFormDataInEditMode(localData)
        showUpdateProfileSetterAdmin(true);
        setEditMode(true);
      }
    } else if (label === 'Change Password') {
      showChangePasswordSetter(true)
    }
  };

  const updateProfileModalAdmin = (
    <Dialog open={showUpdateProfileAdmin} onClose={handleClose}>
      <DialogTitle>Update Profile</DialogTitle>
      {message &&
        <>
          <Alert severity={message.severity} onClose={() => { setMessage(null); }}>
            <AlertTitle>{message.title}</AlertTitle>
            {message.description}
          </Alert>
          <br />
        </>
      }
      <form >
        {connectorId &&
          <DialogContent sx={{ paddingTop: 0, marginTop: "-20px" }} >
            <div className="row form-fields mt-md-n5">
              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    defaultValue={formDataInEditMode?.accountHolderName}
                    autoFocus
                    margin="dense"
                    id="accountHolderName"
                    // {...register("userFirstName")}
                    label="User Name"
                    fullWidth
                    variant="standard"
                    error={errors?.userFirstName && errors?.userFirstName}
                    helperText={errors?.userFirstName?.message}
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    defaultValue={formDataInEditMode?.connectorPhone}
                    autoFocus
                    id="connectorPhone"
                    margin="dense"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    // {...register("userPhone")}
                    label="Mobile Number"
                    fullWidth
                    variant="standard"
                  // error="Must be a valid phone"
                  // helperText="Must be a valid phone"
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    defaultValue={formDataInEditMode?.connectorEmail}
                    id="connectorEmail"
                    disabled
                    autoFocus
                    margin="dense"
                    // {...register("userEmail")}
                    label="Email"
                    fullWidth
                    variant="standard"
                    error={errors?.userEmail && errors?.userEmail}
                    helperText={errors?.userEmail?.message}
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    defaultValue={formDataInEditMode?.connectorManager}
                    id="connectorManager"
                    margin="dense"
                    // {...register("connectorManager")}
                    label="Connector Manager"
                    fullWidth
                    variant="standard"
                  // // error={errors?.connectorName && errors?.connectorName}
                  // helperText={errors?.connectorName?.message}
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    defaultValue={formDataInEditMode?.accountNumber}
                    id="accountNumber"
                    disabled
                    margin="dense"
                    // {...register("accountNumber")}
                    label="Account Number"
                    fullWidth
                    variant="standard"
                  // // error={errors?.connectorName && errors?.connectorName}
                  // helperText={errors?.connectorName?.message}
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  {/* <InputLabel>Bank Name</InputLabel> */}
                  <Autocomplete
                    disablePortal
                    defaultValue={bankCodeConstants[formDataInEditMode?.bankName]}
                    options={Object.keys(bankNameConstants)}
                    id="bankName"
                    disabled
                    renderInput={(params) => <TextField
                      // {...register("bankName")}
                      {...params} label="Bank Name" />}
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    defaultValue={formDataInEditMode?.ifsc}
                    id="ifsc"
                    disabled
                    margin="dense"
                    // {...register("IFSC_Code")}
                    label="IFSC Code"
                    fullWidth
                    variant="standard"
                  // // error={errors?.connectorName && errors?.connectorName}
                  // helperText={errors?.connectorName?.message}
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  {/* <InputLabel>State</InputLabel> */}
                  <Autocomplete
                    disablePortal
                    defaultValue={stateConstants[formDataInEditMode?.state]}
                    options={Object.values(stateConstants)}
                    id="state"
                    renderInput={(params) => <TextField
                      {...register("loanState")}
                      {...params} label="State" />}
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    defaultValue={formDataInEditMode?.location}
                    id="location"
                    margin="dense"
                    // {...register("location")}
                    label="Connector Location"
                    fullWidth
                    variant="standard"
                  // // error={errors?.location && errors?.location}
                  // helperText={errors?.location?.message}
                  />
                </FormControl>
              </div>
            </div>
          </DialogContent>
        }
        {finadminId &&
          <DialogContent sx={{ paddingTop: 0, marginTop: "-20px" }}>
            <div className="row form-fields mt-md-n5">
              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    autoFocus
                    margin="dense"
                    value={finadminName}
                    onChange={adminNameUpdate}
                    label="admin Name"
                    fullWidth
                    variant="standard"
                    error={errors?.userFirstName && errors?.userFirstName}
                    helperText={errors?.userFirstName?.message}
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    autoFocus
                    type="number"
                    margin="dense"
                    value={finadminPhone}
                    onChange={adminPhoneUpdate}
                    label="Mobile Number"
                    fullWidth
                    variant="standard"
                    error={errors?.userPhone && errors?.userPhone}
                    helperText={errors?.userPhone?.message}
                  />
                </FormControl>
              </div>

              <div className="col-md-6">
                <FormControl variant="standard" className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    autoFocus
                    margin="dense"
                    value={finadminEmail}
                    onChange={adminEmailUpdate}
                    disabled
                    label="Email"
                    fullWidth
                    variant="standard"
                    error={errors?.userEmail && errors?.userEmail}
                    helperText={errors?.userEmail?.message}
                  />
                </FormControl>
              </div>


            </div>
          </DialogContent>
        }
        <DialogActions sx={{ padding: "0 20px 20px 0" }}>
          <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleClose} type="reset">
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.leadButton}
            onClick={submitUpdateProfileAdmin}
            disable={isLoading}
          >
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );



  const changePasswordModal = (
    <Dialog open={showChangePassword} onClose={handleClose}>
      <DialogTitle>Change Password</DialogTitle>
      {message &&
        <>
          <Alert severity={message.severity} onClose={() => { setMessage(null); }}>
            <AlertTitle>{message.title}</AlertTitle>
            {message.description}
          </Alert>
          <br />
        </>
      }
      <form onSubmit={handleSubmit(submitPasswordChange)}>
        <DialogContent sx={{ paddingTop: 0, marginTop: "-20px" }}>
          <div className="row form-fields mt-md-n5">
            <div className="col-md-12">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("oldPassword")}
                  label="Old Password"
                  fullWidth
                  variant="standard"
                  // defaultValue={watch("oldPassword")}
                  error={errors?.oldPassword && errors?.oldPassword}
                  helperText={errors?.oldPassword?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-12">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("newPassword")}
                  label="New Password"
                  fullWidth
                  variant="standard"
                  // defaultValue={watch("newPassword")}
                  error={errors?.newPassword && errors?.newPassword}
                  helperText={errors?.newPassword?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-12">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("confirmNewPassword")}
                  label="Confirm New Password"
                  fullWidth
                  variant="standard"
                  // defaultValue={watch("confirmNewPassword")}
                  error={errors?.confirmNewPassword && errors?.confirmNewPassword}
                  helperText={errors?.confirmNewPassword?.message}
                />
              </FormControl>
            </div>
          </div>

        </DialogContent>
        <DialogActions sx={{ padding: "0 30px 20px 0" }}>
          <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.leadButton}
            type="submit"
          // disable={isLoading ? "true" : "false"}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <>
      {message &&
        <>
          <Alert severity={message.severity} onClose={() => { setMessage(null); }}>
            <AlertTitle>{message.title}</AlertTitle>
            {message.description}
          </Alert>
          <br />
        </>
      }
      <Box className={clsx(classes.profileRoot, "Cmt-profile-pic")}>
        <CmtDropdownMenu
          onItemClick={onItemClick}
          TriggerComponent={
            <Box className={classes.profileContent} style={{ backgroundColor: '#dfe9ff', padding: '10px', borderRadius: '10px' }}>
              <div className={classes.title}><a>{user.userName}</a></div>
              <KeyboardArrowDownIcon fontSize="small" sx={{ color: "#000" }} />
            </Box>
          }
          items={actionsList}
        />
        {updateProfileModalAdmin}
        {changePasswordModal}
      </Box>
    </>
  );
};

export default UserDropDown;
