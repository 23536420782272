/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import ApiStatusCheck from "./ApiStatusCheck";
import LeadsDataService from "./LeadsDataService";
import TotalProjections from "./TotalProjections";
import GetLeadsDataService from "./GetLeadsDataService";
import PeriodicProjectionService from "./PeriodicProjection";
import ProductProjectionService from "./ProductProjection";
import LoginService from "./Auth/LoginService";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    apiStatusCheck: ApiStatusCheck,
    leadsDataService: LeadsDataService,
    totalProjections: TotalProjections,
    getLeadsDataService: GetLeadsDataService,
    periodicProjectionService: PeriodicProjectionService,
    productProjectionService: ProductProjectionService,
    loginService: LoginService,
  });
